import { createContext } from "react"
import { ContextPropType } from "@Types/ContextPropType"
import { AuthActionTypes } from "@Context/actionsTypes/AuthTypes"
import { IAuth } from "@Interfaces/IUser"
import { AuthReducerInit } from "@Context/inits/AuthReducerInit"

// this is for the context is for the user login
export const AuthContext = createContext<ContextPropType<IAuth, AuthActionTypes>>({
  state: AuthReducerInit(),
  dispatch: () => null,
})
