import { ButtonStyled } from "@Theme/common/Button.styled"
import ContentDetailOrderActionsStyled from "@Theme/common/ContentDetailOrderActions.styled"
import { IconCheck } from "@Theme/common/Icon.styled"
import { ITransitAction } from "@Interfaces/IShippingGroupAction"
import { useLocation } from "react-router-dom"
import { useContext } from "react"
import { AuthContext } from "@Context/context/AuthContext"

const TransitAction = ({ loadingTransit }: ITransitAction) => {
  // TODO: This could be refactorized
  const { pathname } = useLocation<{ type: string }>()
  const authState = useContext(AuthContext)
  const website: any = authState?.state.user?.mainGroup.id
  let transit_to_source = pathname.includes("transit_to_source")
  let in_transit_for_reception = pathname.includes("in_transit_for_reception")
  if (website === "privilege" && !transit_to_source) return null

  const renderBtnTitle = (): string => {
    if(transit_to_source)  return "recibido"
    if(in_transit_for_reception) return "recepción"

    return "entrega"
  }

  return (
    <ContentDetailOrderActionsStyled>
      <ButtonStyled variant="primary" loadingButton={loadingTransit} disabled={loadingTransit}>
        <IconCheck />
        {/* // TODO: This could be refactorized */}
        <span>Confirmar {renderBtnTitle()}</span>
      </ButtonStyled>
    </ContentDetailOrderActionsStyled>
  )
}

export default TransitAction
