import Notification from "@Components/Commons/Notifications/Notification"
import { INotification } from "@Interfaces/INotification"
import { NotificationListStyled } from "@Theme/common/Notification.styled"

const NotificationList = ({ notifications }: { notifications: Array<INotification> }) => {
  return (
    <NotificationListStyled>
      {notifications.map(({ id, title, type, message, delay }) => (
        <Notification key={id} id={id} message={message} title={title} type={type} delay={delay} />
      ))}
    </NotificationListStyled>
  )
}

export default NotificationList
