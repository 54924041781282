import styled from "styled-components"
import { displays } from "@Theme/base/Variables"

const BackBottonStyled = styled.section`
  ${displays.flexBetween};
  width: 100%;
  padding: 0.5rem;
  background: ${({ theme }) => theme.palette.grey10};

  & .icon-go-to-back {
    color: ${({ theme }) => theme.palette.grey60};
    font-size: 2rem;
    cursor: pointer;
  }
`

export default BackBottonStyled
