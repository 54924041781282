import { ClientTypes } from "@ContextActionsTypes"

/**
 * Reducers - Information about the client's
 * TODO: Make interfaces
 *
 * @version 1.0.1
 * @author Naldo Duran <naldorck@gmail.com>
 * @returns {function}
 */
const ClientReducer = (state: any, action: any): any => {
  switch (action.type) {
    case ClientTypes.set_website:
      return {
        ...state,
        website: action.payload,
      }

    case ClientTypes.set_env:
      return {
        ...state,
        env: action.payload,
      }

    default:
      return state
  }
}

export default ClientReducer
