import LogoClient from "@Components/Commons/BrandClient/LogoClient"
import { ITagInfoCustomer } from "@Interfaces/ITagInfoCustomer"
import { dataFields, Fields } from "@Components/OrderDetail/TagPrint/render"

const TagInfoCustomer = (props: ITagInfoCustomer) => {
  return (
    <div className="info-customer">
      <LogoClient />
      <Fields field={dataFields} data={props} />
    </div>
  )
}

export default TagInfoCustomer
