import { useContext, useEffect, useState } from "react"
import { getOrderByStatus } from "@Services/ShippingGroupService"
import Logger from "@Classes/Logger"
import { OderGroupingType } from "@Types/OrderType"
import { IOrderShippinggroup } from "@Interfaces/IOrder"
import { getValueShippinggroupStatusEnum, groupBy } from "@Helpers/UtilsHelper"
import { formatDate } from "@Helpers/FormatDateHelper"
import configEnv from "@Config/configEnv"
import { STORAGE_SORT_ORDER, TODAY, YERTERDAY } from "@Helpers/constHelper"
import useCancelToken from "@Hooks/UseCancelToken"
import { IRequestService } from "@Interfaces/IRequestService"
import { IRangeExtend } from "@Interfaces/IContentCelendar"
import { TransactionCodeEnum } from "@Enums/TransactionCodeEnum"
import { AuthContext } from "@Context/context/AuthContext"
import { GlobalContext } from "@Context/context/GlobalContext"
import { typeVariantObject } from "@Types/CommonsTypes"
import { OptionsFilterEnum } from "@Enums/OptionsFilterEnum"
import { SortOrderEnum } from "@Enums/OrderEnum"
import useLocalStorage from "@Hooks/useLocalStorage"
import clientShippingTypes from "@Website/data/shippingTypes"
import Env from "@Classes/Env"

const INITIAL_PAGE = 0

const useGetSgsByStatusCore = (
  status: string,
  filterOrder: typeVariantObject = { [OptionsFilterEnum.query]: undefined },
) => {
  const [ordersGroup, setOrdersGroup] = useState<OderGroupingType>({})
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(INITIAL_PAGE)
  const [maxPage, setMaxPage] = useState(0)
  const [rangeDate, setRangeDate] = useState<IRangeExtend>()
  const [search, setSearch] = useState<typeVariantObject>(filterOrder)
  const [sort, setSort] = useLocalStorage(STORAGE_SORT_ORDER, SortOrderEnum.desc)
  const [customFilters, setCustomFilters] = useState<typeVariantObject | null>(null)

  const {
    state: { user },
  } = useContext(AuthContext)

  const { errorHander } = useContext(GlobalContext)

  const { isCancel, newCancelToken } = useCancelToken()

  //Make the request to build the current list of SGS
  useEffect(() => {
    const getOrdersGroupByDate = async () => {
      const statusValue: any = getValueShippinggroupStatusEnum(status)
      if (!user?.currentSources) {
        Logger.error("Not found sources")
        return
      }

      const requestBody: IRequestService = {
        maxRecords: configEnv.maxRecords,
        skipRecords: page * configEnv.maxRecords,
        filter: {
          shippingType: clientShippingTypes(),
        },

        sort: { orderCreationDate: sort },
        project: [
          "id",
          "orderId",
          "orderCreationDate",
          "channel",
          "shippingType",
          "salesChannelId",
          "currentStatus",
          "status",
          "source",
          "target",
          "custom",
        ],
        range: rangeDate
          ? {
              from: `${rangeDate.startDate && formatDate(rangeDate.startDate, "yyyy-MM-dd")} 00:00:00`,
              to: `${rangeDate.endDate && formatDate(rangeDate.endDate, "yyyy-MM-dd")} 23:59:59`,
              type: "absolute",
            }
          : undefined,
      }

      if (search.hasOwnProperty(OptionsFilterEnum.query)) {
        requestBody.query = search[OptionsFilterEnum.query]
      } else {
        requestBody.filter = {
          ...requestBody.filter,
          ...search,
        }
      }

      /// custom filters
      if (customFilters) {
        requestBody.filter = {
          ...requestBody.filter,
          ...customFilters,
        }
      }

      // TODO: Make interface
      let purgedOrders: any = []
      let response: any = {}
      let responseRecords = []
      let responseTotalRecordsMatched: number = 0
      let responseTargetRecords: any[] = []
      let responseTargetTotalRecordsMatched: number = 0
      let responseError: any = null
      let orders: any

      // Block render page
      setLoading(true)

      // GET Information from orders with source.id filter active
      try {
        if (Env.sg_shippingtype_rt_status.includes(status.toUpperCase())) {
          requestBody.filter = {
            ...requestBody.filter,
            "target.source.id": user.currentSources,
          }
        } else {
          requestBody.filter = {
            ...requestBody.filter,
            "source.id": user.currentSources,
          }
        }

        response = await getOrderByStatus(statusValue, requestBody, newCancelToken())
        response = response.data.message
        responseRecords = response.records
        responseTotalRecordsMatched = response.totalRecordsMatched
      } catch (error: any) {
        if (isCancel(error)) return
        responseError = error.response?.status
        Logger.error("No results with source.id filter active:", user.currentSources.toString())
      }

      try {
        responseError = null
        requestBody.filter = {
          "target.source.id": user.currentSources,
        }
        response = await getOrderByStatus(statusValue, requestBody, newCancelToken())
        response = response.data.message
        responseTargetRecords = response.records
        responseTargetTotalRecordsMatched = response.totalRecordsMatched
      } catch (error: any) {
        if (isCancel(error)) return
        if (!responseRecords.length) {
          responseError = error.response?.status
          Logger.error("No results with target.source.id filter active:", user.currentSources.toString())
        }
      }

      // If doesn't exist orders, return error message
      if (!!responseError) {
        if (responseError === TransactionCodeEnum.notFound) {
          setLoading(false)
          setOrdersGroup(null)
          return
        }
        errorHander?.dispatch({ hasError: true, code: responseError })
      }

      // Concat orders separated by source.id and target.source.id
      purgedOrders = responseRecords.concat(responseTargetRecords)
      purgedOrders = Array.from(new Set(purgedOrders.map((a: any) => a.id))).map((id) => {
        return purgedOrders.find((a: any) => a.id === id)
      })

      // Calculate the total records to paginate
      const totalRecordsMatched =
        (responseTotalRecordsMatched + responseTargetTotalRecordsMatched) / configEnv.maxRecords

      // Order the orders by orderCreationDate
      orders = groupBy("orderCreationDateFormat", formatDateOrders(purgedOrders))
      setOrdersGroup(orders)
      setMaxPage(Math.ceil(totalRecordsMatched))
      setLoading(false)
    }

    getOrdersGroupByDate()

    // eslint-disable-next-line
  }, [status, page, rangeDate, search, sort, customFilters])

  const onSelectedRangeDate = (range?: IRangeExtend) => {
    setRangeDate(range)
    setPage(INITIAL_PAGE)
  }

  const onChangeSortDate = (sort: SortOrderEnum) => {
    setSort(sort)
    setPage(INITIAL_PAGE)
  }

  const handleChangeCustomFilters = (filter: typeVariantObject | null) => {
    setCustomFilters(filter)
    setPage(INITIAL_PAGE)
  }

  const formatDateOrders = (orders: IOrderShippinggroup[]) => {
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)

    const todayFormat = formatDate(today)
    const yesterDayFormat = formatDate(yesterday)

    orders.forEach((order) => {
      let orderDate = formatDate(order.orderCreationDate)
      order.orderCreationDateFormat =
        orderDate === todayFormat ? TODAY : orderDate === yesterDayFormat ? YERTERDAY : orderDate
    })

    return orders
  }

  return {
    ordersGroup,
    loading,
    setPage,
    maxPage,
    page,
    onSelectedRangeDate,
    setSearch,
    onChangeSortDate,
    handleChangeCustomFilters,
  }
}

export default useGetSgsByStatusCore
