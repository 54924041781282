import styled from "styled-components"
import { displays } from "@Theme/base/Variables"

const UserProfileFormStyled = styled.form`
  ${displays.flexColumn};
  padding: 1.5rem 0;
  gap: 1.5rem;
  & .field-input {
    padding: 0 1rem;

    &.new-password-group {
      width: 100%;
      margin-top: 1rem;
      ${displays.flexColumn};
      gap: 1rem;

      & > button {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
`

export default UserProfileFormStyled
