import { LoginFormStyled, InputLogin } from "./LoginForm.styled"
import { ButtonStyled } from "@Theme/common/Button.styled"
import { ILoginBody } from "@Interfaces/ILoginBody"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { ErrorMessageInputStyled } from "@Theme/common/Input.styled"
import useLogin from "@Hooks/UseLogin"
import { schemaLoginForm } from "@Config/ShemaForms"
import { IconLock, IconUser } from "@Theme/common/Icon.styled"
import AlertStyled from "@Theme/common/Alert.styled"

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginBody>({
    resolver: yupResolver(schemaLoginForm),
  })

  const { loading, loginUser, error } = useLogin()

  return (
    <LoginFormStyled>
      {
        /* Refactor this block */
        window.$website === "wom" ? (
          <img
            src={require(`@Theme/assets/img/LogosClients/${window.$website}-white.png`).default}
            alt="logo"
            className="image-logo"
          />
        ) : (
          <img
            src={require(`@Theme/assets/img/login_logo_omnix_white.svg`).default}
            className="image-logo"
            alt="logo"
          />
        )
      }

      <form className="form-container" onSubmit={handleSubmit(loginUser)}>
        <ErrorMessageInputStyled hasError={!!errors.user}>
          <InputLogin>
            <IconUser />
            <input id="user" type="text" placeholder="Usuario" {...register("user")} />
          </InputLogin>
        </ErrorMessageInputStyled>
        <ErrorMessageInputStyled hasError={!!errors.password}>
          <InputLogin>
            <IconLock />
            <input
              id="password"
              type="password"
              placeholder="Contraseña"
              {...register("password")}
            />
          </InputLogin>
        </ErrorMessageInputStyled>
        {error && (
          <AlertStyled variant="warning" className="error-message">
            {error}
          </AlertStyled>
        )}
        <ButtonStyled
          variant="submit"
          loadingButton={loading}
          disabledButton={!!errors.user || !!errors.password}
        >
          <span>Iniciar Sesión</span>
        </ButtonStyled>
      </form>
    </LoginFormStyled>
  )
}

export default LoginForm
