import styled from "styled-components"
import { displays } from "@Theme/base/Variables"
import { Link, NavLink } from "react-router-dom"

/// Link react router
export const LinkIconStyled = styled(Link)`
  ${displays.flexBase}
  text-decoration: none;
  gap: 0.5rem;
`
// NavLink Recat router dom
export const NavLinkStyled = styled(NavLink)`
  border-radius: 16px;
  text-decoration: none;
`

export const LinkBase = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.grey60};
  font-size: 1.075rem;
  cursor: pointer;
  font-weight: 700;

  &:hover {
    text-decoration: underline;
  }
`
