import Img from "@Components/Commons/Image/Image"
import ProductDetail from "@Components/Core/ProductBase/ProductDetail"
import SummaryProductCardStyled from "@Components/OrderDetail/SummaryProducts/SummaryProductCard/SummaryProductCard.styled"
import { useGetProductInfoByClient } from "@Hooks/UseGetMultiClient"
import { IProcessItem } from "@Interfaces/IItemsOrder"
import { IconCheck, IconReject } from "@Theme/common/Icon.styled"

const SummaryProductCard = ({
  itemOrder,
  hasIcon = false,
}: {
  itemOrder: IProcessItem
  hasIcon?: boolean
}) => {
  const productFields = useGetProductInfoByClient(itemOrder)
  return (
    <SummaryProductCardStyled hasIcon={hasIcon} isConfirm={itemOrder.isConfirm}>
      <p className="item-name">{itemOrder.name}</p>
      <div className="body-product-card">
        <div className="info-tem">
          <ProductDetail fields={productFields} hasPlaceHolder={false} />
        </div>

        {itemOrder.custom?.thumbnail && (
          <Img url={itemOrder.custom.thumbnail} alt={itemOrder.name} />
        )}
        {hasIcon ? itemOrder.isConfirm ? <IconCheck /> : <IconReject /> : null}
      </div>
    </SummaryProductCardStyled>
  )
}

export default SummaryProductCard
