import styled, { css } from "styled-components"
import { displays } from "@Theme/base/Variables"

const SummaryProductCardStyled = styled.article<{ hasIcon?: boolean; isConfirm?: boolean }>`
  padding: 1rem 0.5rem;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.palette.white};
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.06);

  & .item-name {
    color: ${({ theme }) => theme.palette.grey90};
    font-size: 1.14rem;
    margin-bottom: 0.5rem;
  }

  & .body-product-card {
    ${displays.flexBetween};

    & .info-tem {
      & > p {
        ${displays.flexBase};
        gap: 0.3rem;
        margin: 0.2rem 0;
        color: ${({ theme }) => theme.palette.grey60};
        & span {
          &.order-id {
            color: ${({ theme }) => theme.palette.blueDark};
          }
        }
      }
    }

    ${({ hasIcon, isConfirm, theme }) =>
      hasIcon &&
      css`
        & > svg {
          font-size: 3rem;
          color: ${isConfirm
            ? theme.alert.success.backgroundColor
            : theme.alert.danger.backgroundColor};
        }
      `}
  }
`

export default SummaryProductCardStyled
