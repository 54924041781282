import axiosClient from "@Config/axiosClient"
import { IResponseService } from "@Interfaces/IResponseService"
import { ISource } from "@Interfaces/ISource"
import configEnv from "@Config/configEnv"
import { AxiosResponse, CancelToken } from "axios"

const clientAxios = axiosClient("OIM")

export const getSourceInfoById = async (
  sourceId: string,
  cancelToken: CancelToken,
): Promise<AxiosResponse<IResponseService<ISource>>> => {
  const client = clientAxios.getClient()

  const url = `${configEnv.urlSources}`
  const requestBody = {
    idsOrAliases:[sourceId],
    project: ["id", "name", "custom"]
  }

  const response = await client.post<IResponseService<ISource>>(
    url, requestBody, {
    headers: { "Content-Type": "application/json" },
    data: {},
    cancelToken,
  })

  return response
}
