import { ButtonStyled } from "@Theme/common/Button.styled"
import ContentDetailOrderActionsStyled from "@Theme/common/ContentDetailOrderActions.styled"
import { IconCheck } from "@Theme/common/Icon.styled"
import { IPickingAction } from "@Interfaces/IShippingGroupAction"

const PickingAction = ({ loadingConfirm, onClickConfirmOrder }: IPickingAction) => {
  return (
    <ContentDetailOrderActionsStyled>
      <ButtonStyled
        variant="primary"
        loadingButton={loadingConfirm}
        disabled={loadingConfirm}
        onClick={onClickConfirmOrder}
      >
        <IconCheck />
        <span>Confirmar Orden</span>
      </ButtonStyled>
    </ContentDetailOrderActionsStyled>
  )
}

export default PickingAction
