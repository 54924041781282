import { MouseEvent, useRef, useState } from "react"
import Modal from "@Components/Commons/Modal/Modal"
import ContentCalendar from "@Components/Core/Order/FilterDate/ContentCalendar/ContentCalendar"
import OrderFilterDateStyled from "@Components/Core/Order/FilterDate/OrderFilterDate.styled"
import { IconCalendar, IconArrowDownMain, IconClose } from "@Theme/common/Icon.styled"
import { IOrderFilterDate, IRangeExtend } from "@Interfaces/IContentCelendar"
import { formatDate } from "@Helpers/FormatDateHelper"

const OrderFilterDate = ({ onSelectedRangeDate }: IOrderFilterDate) => {
  const formatString = "dd MMM yyyy"

  const [rangeSelected, setRangeSelected] = useState<IRangeExtend>()

  const refModal = useRef<React.ElementRef<typeof Modal>>(null)

  //events
  const handleClickCalendar = () => refModal.current?.open()

  const handleChangeRangeDate = (range: IRangeExtend) => {
    setRangeSelected(range)
    onSelectedRangeDate(range)
  }

  const handleClickClearFilter = (e: MouseEvent<SVGAElement>) => {
    e.stopPropagation()
    setRangeSelected(undefined)
    onSelectedRangeDate(undefined)
  }

  return (
    <>
      <OrderFilterDateStyled>
        <p>Filtra por fechas</p>
        <div className="content-date" onClick={handleClickCalendar}>
          <IconCalendar className="calendar" />
          <div className="info-date">
            {rangeSelected ? (
              <>
                <span>
                  {`${
                    rangeSelected.startDate && formatDate(rangeSelected.startDate, formatString)
                  } - ${rangeSelected.endDate && formatDate(rangeSelected.endDate, formatString)}`}
                </span>
                <IconClose onClick={handleClickClearFilter} />
              </>
            ) : (
              <>
                <span>Seleccione...</span>
                <IconArrowDownMain />
              </>
            )}
          </div>
        </div>
      </OrderFilterDateStyled>
      <Modal ref={refModal}>
        <ContentCalendar
          closeModal={refModal.current?.close}
          onChangeRangeDate={handleChangeRangeDate}
          initRange={rangeSelected}
        />
      </Modal>
    </>
  )
}

export default OrderFilterDate
