import styled from "styled-components"
import { displays } from "@Theme/base/Variables"
import img from "@Theme/assets/img/bg_login.png"

export const LoginFormStyled = styled.div`
  ${displays.flexColumn};
  min-height: 100vh;
  background-image: url(${img});
  background-repeat: no-repeat;
  background-size: cover;

  & .image-logo {
    margin-bottom: 4rem;
  }

  & .form-container {
    width: 300px;
    ${displays.flexColumn};
    gap: 1rem;

    & .error-message {
      border-radius: 10px;
      font-size: 15px;
      padding: 0.5rem;
    }
    & > button {
      margin: 2rem;
      width: 100%;
    }
  }
`

export const InputLogin = styled.div`
  position: relative;
  color: ${({ theme }) => theme.palette.white};
  width: 100%;
  & > input {
    border-radius: 23px;
    background: ${({ theme }) => theme.palette.transparentGrey};
    border: 1px solid ${({ theme }) => theme.palette.grey75};
    width: 100%;
    padding-left: 3rem;
    text-align: left;
    height: 47px;

    &:required {
      border: 1px solid ${({ theme }) => theme.alert.danger.backgroundColor};
    }
    &:invalid {
      border: 1px solid ${({ theme }) => theme.alert.danger.backgroundColor};
    }

    &::-ms-clear {
      display: none;
    }

    &:-webkit-autofill {
      -webkit-text-fill-color: ${({ theme }) => theme.palette.white};
      box-shadow: 0 0 0px 1000px ${({ theme }) => theme.palette.transparentGrey} inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }
  & > svg {
    position: absolute;
    color: ${({ theme }) => theme.palette.white};
    font-size: 1.5rem;
    transform: translateY(-50%);
    top: 50%;
    left: 0.7rem;
    pointer-events: none;
  }
`
