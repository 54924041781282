import { IBarcode } from "@Interfaces/IBarcode"
import { useBarcode } from "react-barcodes"

const Barcode = ({ value, fontSize = 16, height = 70 }: IBarcode) => {
  const { inputRef } = useBarcode({
    value,
    options: {
      font: "system-ui",
      format: "CODE128",
      fontSize,
      height,
    },
  })

  return <canvas style={{ width: "100%" }} ref={inputRef} />
}

export default Barcode
