import { useContext, useEffect } from "react"
import { GlobalContext } from "@Context/context/GlobalContext"
import { INotification } from "@Interfaces/INotification"
import { NotificationStyled } from "@Theme/common/Notification.styled"
import { IconCheck, IconError, IconInfo, IconClose } from "@Theme/common/Icon.styled"

const Notification = ({ id, message, title, type, delay = 5000 }: INotification) => {
  const { notification } = useContext(GlobalContext)

  useEffect(() => {
    const timeout = setTimeout(
      () => notification?.dispatch({ type: "DELETE_NOTIFICATION", payload: id }),
      delay,
    )

    return () => clearTimeout(timeout)
  }, [notification, id, delay])

  const renderIconByType = () => {
    switch (type) {
      case "success":
        return <IconCheck />
      case "danger":
        return <IconError />
      case "info":
        return <IconInfo />
    }
  }

  const handleCloseNotification = () => {
    notification?.dispatch({ type: "DELETE_NOTIFICATION", payload: id })
  }

  return (
    <NotificationStyled variant={type}>
      <div className="content-type-icon">{renderIconByType()}</div>
      <div className="body-notification">
        <p className="title">{title}</p>
        <p className="message">{message}</p>
      </div>
      <div className="close-notification" role="button" onClick={handleCloseNotification}>
        <IconClose />
      </div>
    </NotificationStyled>
  )
}

export default Notification
