import { IPropsButtonSecunday } from "@Interfaces/IPropsButton"
import { ButtonSecondaryStyled } from "@Theme/common/Button.styled"
import { IconCheck } from "@Theme/common/Icon.styled"

const ButtonOptionCalendar = ({ text, active, onclick, id }: IPropsButtonSecunday) => {
  return (
    <ButtonSecondaryStyled onClick={onclick} active={active} id={id.toString()}>
      {active && <IconCheck className="icon" />}
      {text}
    </ButtonSecondaryStyled>
  )
}

export default ButtonOptionCalendar
