import { FunctionComponent, useEffect } from "react"
import IPage from "@Interfaces/IPage"
import Logger from "@Classes/Logger"
import LoginForm from "@Components/User/Login/LoginForm"

const LoginScreen: FunctionComponent<IPage> = (props: any) => {
  const { name } = props

  useEffect(() => {
    Logger.info(`Loading ${name}`)
  }, [name])

  return <LoginForm/>
}

export default LoginScreen