import { useState, useEffect, useContext } from "react"
import { GlobalContext } from "@Context/context/GlobalContext"
import { useHistory } from "react-router-dom"
import { OderGroupingType } from "@Types/OrderType"
import Logger from "@Classes/Logger"
import { executeShipingGroupAction } from "@Services/ShippingGroupService"
import { TransactionCodeEnum } from "@Enums/TransactionCodeEnum"
import useConditionalsGroupOrder from "./UseConditionalsGroupOrder"
import { generateSelectedSgsPdf } from "@Helpers/pdfGenerationHelper"
import { getOrderById } from "@Services/OrderServices"
import useCancelToken from "@Hooks/UseCancelToken"

const useChekedOrderList = (ordersGroup: OderGroupingType) => {
  const [ordersSGChecked, setOrdersSGChecked] = useState<Array<string>>([])
  const [ordersSGCheckedToPrint, setOrdersSGCheckedToPrint] = useState<Array<any>>([])
  const [selectAll, setSelectAll] = useState(false)
  const [disabledUpdateSG, setDisabledUpdateSG] = useState(true)
  const [loadingUpdateSG, setLoadingUpdateSG] = useState(false)
  const { newCancelToken } = useCancelToken()

  const { notification, errorHander } = useContext(GlobalContext)
  const history = useHistory()

  const { canSelectMultiple, statusGroup, typeStatus } = useConditionalsGroupOrder()

  useEffect(() => {
    const anySelected = ordersSGChecked.length === 0
    setDisabledUpdateSG(anySelected)
  }, [ordersSGChecked])

  const getInfoOrderToPrint = async (orderId: string) => {
    try {
      const {
        data: { message: order },
      } = await getOrderById(orderId, newCancelToken())
      return order
    } catch (error: any) {
      Logger.error(error)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  const checkAllOrders = (checked: boolean) => {
    setSelectAll(!selectAll)

    if (!checked) {
      setOrdersSGChecked([])
      setOrdersSGCheckedToPrint([])
      return
    }
    if (ordersGroup) {
      let ordersId: Array<string> = []
      let letOrdersToPrint: Array<any> = []
      const orders = Object.keys(ordersGroup)

      if (statusGroup?.predicate) {
        const { predicate } = statusGroup
        ordersId = orders.flatMap((key) => ordersGroup[key].filter(predicate).map(({ id }) => id))
        letOrdersToPrint = orders.flatMap((key) =>
          ordersGroup[key].filter(predicate).map(({id, orderId, source, target}) =>
            ({id, orderId, source, target})))
      } else {
        ordersId = orders.flatMap((key) => ordersGroup[key].map(({ id }) => id))
        letOrdersToPrint = orders.flatMap((key) => ordersGroup[key].map(({
          id, orderId, source, target
        }) => ({id, orderId, source, target})))
      }
      setOrdersSGChecked(ordersId)
      setOrdersSGCheckedToPrint(letOrdersToPrint)
    }
  }

  const updateShippingGroupAction = async () => {
    if (!statusGroup) return
    try {
      setLoadingUpdateSG(true)
      let resultOrdersPrint: any[] = []
      if (window?.$website === "wom") {
        let ordersToPrint = ordersSGCheckedToPrint.map(async sg => {
          const order = await getInfoOrderToPrint(sg.orderId)
          return {...sg, order}
        })
        resultOrdersPrint = await Promise.all(ordersToPrint)
      }
      
      const executeOrders = ordersSGChecked.map((idSG) =>
        executeShipingGroupAction({
          shippingGroupId: idSG,
          action: statusGroup.action,
        }),
      )

      const [resultUpdateOrders] = await Promise.all(executeOrders)

      setLoadingUpdateSG(false)

      if (resultUpdateOrders.data.code === TransactionCodeEnum.ok) {
        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: "se actualizo correctamente los SG’s",
            title: "Actualización SG’s",
            type: "success",
          },
        })
        window?.$website === "wom" && generateSelectedSgsPdf(resultOrdersPrint, statusGroup.action)

        setTimeout(() => history.push("/"), 1500)
      }
    } catch (error: any) {
      setLoadingUpdateSG(false)
      Logger.error(error)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  return {
    canSelectMultiple,
    ordersSGChecked,
    selectAll,
    setOrdersSGChecked,
    checkAllOrders,
    statusSG: typeStatus,
    disabledUpdateSG,
    loadingUpdateSG,
    updateShippingGroupAction,
    conditionChecked: statusGroup?.predicate,
    labelGroupAction: statusGroup?.labelAction,
    ordersSGCheckedToPrint,
    setOrdersSGCheckedToPrint
  }
}

export default useChekedOrderList
