import { ComponentsType } from "@Types/CommonsTypes"
import { lazy } from "react"

const ManualTransition = lazy(() => import("@Components/Core/ProductTransition/ManualTransition"))
const TagConsolidation = lazy(() => import("@Webapp-Clients/PRVL/Components/TagConsolidation"))
const TagCustomOrder = lazy(() => import("@Webapp-Clients/PRVL/Components/TagCustomOrder"))
const CustomFilters = lazy(() => import("@Webapp-Clients/PRVL/Components/CustomFilters"))

export const privilege: ComponentsType = {
  ProductTransition: ManualTransition,
  TagConsolidation,
  TagCustomOrder,
  CustomFilters,
}
