import styled from "styled-components"
import { displays } from "@Theme/base/Variables"

const ProductOrderDetailStyled = styled.section`
  padding: 1rem;
  & .content-info-product {
    ${displays.flexColumn}
    gap: 1rem;
    align-items: initial;
    padding: 1rem 0.5rem;
    color: ${({ theme }) => theme.palette.grey90};
    border-radius: 7px;
    background: ${({ theme }) => theme.palette.white};

    & .product-name {
      font-size: 1.143rem;
      font-weight: 500;
    }
  }
`

export const ProductCounStyled = styled.section`
  ${displays.flexCenter};
  gap: 0.5rem;
  margin-bottom: 0.5rem;

  & .product-count {
    border-radius: 20px;
    background-color: ${({ theme }) => theme.palette.grey10};
    color: ${({ theme }) => theme.palette.grey60};
    box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.06);
    font-size: 1.571rem;
    font-weight: bold;
    text-align: center;
    width: 68px;
    user-select: none;
  }
`
export const ProductInfoDetail = styled.article`
  ${displays.flexWrap};
  justify-content: center;
  gap: 1rem;

  & .info {
    margin-bottom: 0.5rem;
  }
`

export const MessageLoadSim = styled.div`
  ${displays.flexBase};
  gap: 1rem;
`

export default ProductOrderDetailStyled
