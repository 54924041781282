import { IPagination } from "@Interfaces/IPagination"
import ReactPaginate from "react-paginate"
import PaginateStyled from "@Components/Commons/Pagination/Pagination.styled"
import { IconArrowLeft, IconArrowRigth } from "@Theme/common/Icon.styled"

const Paginate = ({ pageCount, onChangePage, elementFocus, pageInit }: IPagination) => {
  const handlePageChange = ({ selected }: { selected: number }) => {
    onChangePage(selected)
    elementFocus?.current?.scrollIntoView({ behavior: "smooth" })
  }

  if (pageCount === 0) return null
  return (
    <PaginateStyled>
      <ReactPaginate
        previousLabel={<IconArrowLeft />}
        previousLinkClassName="prev-next"
        nextLabel={<IconArrowRigth />}
        nextLinkClassName="prev-next"
        breakLabel={"..."}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        activeClassName={"active"}
        onPageChange={handlePageChange}
        forcePage={pageInit}
      />
    </PaginateStyled>
  )
}

export default Paginate
