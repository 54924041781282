import CardOrdeItem from "@Components/Core/Order/CardOrder/CardOrdeItem"
import { GroupItemStyled } from "@Theme/common/Paragraph.styled"
import { GridContainer } from "@Theme/common/Grid.styled"
import { useCallback } from "react"
import { OrderListType } from "@Types/OrderType"
import Checkbox from "@Components/Commons/CheckBox"
import useOrderList from "@Components/Core/Order/hooks/UseChekedOrderList"
import { ButtonStyled } from "@Theme/common/Button.styled"
import { IconCheck } from "@Theme/common/Icon.styled"

const OrderList = ({ ordersGroup, showStatus }: OrderListType) => {
  const {
    checkAllOrders,
    setOrdersSGChecked,
    ordersSGChecked,
    selectAll,
    canSelectMultiple,
    statusSG,
    disabledUpdateSG,
    loadingUpdateSG,
    updateShippingGroupAction,
    conditionChecked,
    labelGroupAction,
    setOrdersSGCheckedToPrint,
  } = useOrderList(ordersGroup)
  

  //events
  const handleClickCheckOrder = useCallback((ev: any, infoSgToPrint: any) => {
    const { id, checked } = ev.target
    if (!checked) {
      setOrdersSGChecked((prevOrderSG) => {
        return prevOrderSG.filter((sg) => sg !== id)
      })
      setOrdersSGCheckedToPrint(prevValue => 
        prevValue.filter((sg) => sg.id !== id)
      )
      return
    }
    setOrdersSGChecked((prevOrderSG) => [...prevOrderSG, id])
    setOrdersSGCheckedToPrint(prevValue => [...prevValue, infoSgToPrint])
    },[setOrdersSGChecked, setOrdersSGCheckedToPrint],
  )

  const handleClickSelectAll = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = ev.target
    checkAllOrders(checked)
  }

  const handleClickUpdateOrders = () => {
    updateShippingGroupAction()
  }
  return (
    <>
      {ordersGroup && (
        <>
          {canSelectMultiple && (
            <div className="content-dispatch-action">
              <Checkbox
                id="selectAll"
                name="selectAll"
                onChange={handleClickSelectAll}
                checked={selectAll}
                label="Seleccionar todo"
              />
              <ButtonStyled
                variant="secondary"
                disabledButton={disabledUpdateSG}
                loadingButton={loadingUpdateSG}
                disabled={disabledUpdateSG}
                onClick={handleClickUpdateOrders}
              >
                <IconCheck />
                <span>{labelGroupAction}</span>
              </ButtonStyled>
            </div>
          )}
          {Object.keys(ordersGroup).map((key) => (
            <div className="content-group" key={key}>
              <div className="group-item">
                <GroupItemStyled>{key}</GroupItemStyled>
              </div>
              <GridContainer>
                {ordersGroup[key].map((order) => (
                  <CardOrdeItem
                    key={`${order.orderId}-${order?.id}`}
                    {...order}
                    canSelect={canSelectMultiple && (!conditionChecked || conditionChecked(order))}
                    handleClickSelectOrder={handleClickCheckOrder}
                    isCheckedOrder={ordersSGChecked.includes(order.id)}
                    statusSG={statusSG}
                    showStatus={showStatus}
                  />
                ))}
              </GridContainer>
            </div>
          ))}
        </>
      )}
    </>
  )
}

export default OrderList
