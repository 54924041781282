import { useEffect, useReducer } from "react"
import { Website } from "@Classes"
import { ClientContext } from "@Context/context/index"
import { ClientReducerInit } from "@ContextInits"
import { ClientReducer } from "@ContextReducers"
import { IContext } from "@Interfaces/IContext"
import { ClientTypes } from "@ContextActionsTypes"

/**
 * Constant ClientProvider return information form current website, envitoment and client
 * @param props IContext.
 * @return {Element} ClientProvider.Provider
 */

const ClientProvider = (props: IContext) => {
  const [client, setClient]: any = useReducer(ClientReducer, ClientReducerInit)

  // Accesibility from js files
  const website = new Website()
  window.$env = website.getCurrentEnv()
  window.$website = website.getCurrentWebsite()

  useEffect(() => {
    //  : Accesibility from jsx files
    setClient({
      type: ClientTypes.set_env,
      payload: window.$env,
    })

    setClient({
      type: ClientTypes.set_website,
      payload: window.$website,
    })
  }, [])

  return (
    <ClientContext.Provider value={{ client, setClient }}>{props.children}</ClientContext.Provider>
  )
}

export default ClientProvider
