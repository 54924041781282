import website from "@Website/index"
import useCore from "./clients/useCore";
import usePrivilege from "./clients/usePrivilege";
import useWOM from "./clients/useWOM";

let client = useCore

if (website === "wom") {
  client = useWOM
} else if (website === "privilege") {
  client = usePrivilege
} else {
  client = useCore
}
 
export default client