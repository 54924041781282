import { IGrouping } from "@Interfaces/IGrouping"
import { EShippinggroupStatus } from "@Enums/shippinggroupEmun"
import { typeObjectGeneric, typeVariantObject } from "@Types/CommonsTypes"

/**
 * This Function is to group an array by means of a key. And return an object with the keys that were passed as a parameter.
 * Used to group orders by date
 * @param key String
 * @param array Array<T>
 * @returns {Object}
 */
export const groupBy = <T extends { [key: string]: any }>(
  key: string,
  array: Array<T>,
): IGrouping<T> => {
  return array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key]
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {} as IGrouping<T>)
}

/**
 * This Function is to reduce an array to object means of a key. And return an object with the keys that were passed as a parameter.

 * @param array Array<T>
 * @param key String
 * @returns {Object}
 */
export const keyBy = <T extends typeVariantObject>(
  arr: Array<T>,
  key: string,
): typeObjectGeneric<T> =>
  arr.reduce((acc, current) => {
    acc[current[key]] = current
    return acc
  }, {} as typeObjectGeneric<T>)

/**
 * This function is to capture the value of the sending groups and returns a status
 * @param value String
 * @returns EShippinggroupStatus[]
 */
export const getValueShippinggroupStatusEnum = (value: string | EShippinggroupStatus): EShippinggroupStatus => {
  return EShippinggroupStatus[value as keyof typeof EShippinggroupStatus]
}
/**
 * This function captures a file via base64 and returns a transformed object
 * @param fileBase64 {String}. Text string with the file
 * @param contentType {String}. Type of content
 * @param sliceSize Maximum memory capacity in numeric
 * @returns {blob} returns a transformed object
 */
export const convertBase64ToBlob = (
  fileBase64: string,
  contentType: string = "",
  sliceSize: number = 512,
): Blob => {
  const byteCharacters = atob(fileBase64)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

/**
 * The function is responsible for reducing number assign a letter for example 1500 to 1.5K
 * @param value Number. this is the value it receives from the number to transform
 * @param fromValue Number. this is the base on which the transformation will take place
 * @returns {String}. returns a text string with the value already transformed
 */
export const abbreviateNumber = (value: number, fromValue: number = 1000000) => {
  if (value < fromValue) return value

  const suffixes = ["K", "M", "B", "T"]
  let suffixNum = -1
  let newValue = value

  while (newValue >= 1000) {
    newValue /= 1000
    suffixNum++
  }

  return `${newValue.toPrecision(3)}${suffixes[suffixNum]}`
}
/**
 * getUrlToBlob this function create an Objet Url an return string
 * @param {String} url. Receive a text string
 * @return {String} URL.createObjectURL(newBlob).
 */
export const getUrlToBlob = async (url: string): Promise<string> => {
  const responseUrl = await fetch(url).then((data) => data.blob())
  const newBlob = new Blob([responseUrl], { type: "application/pdf" })
  return URL.createObjectURL(newBlob)
}

export const getFormatedSeriesProduct = (seriales: string | Array<string>): string =>
  Array.isArray(seriales) ? seriales.join(" - ") : seriales.replaceAll(";", " - ")
