import { useEffect, useState, useContext } from "react"
import { useHistory } from "react-router-dom"
import { GlobalContext } from "@Context/context/GlobalContext"
import Logger from "@Classes/Logger"
import { executeShipingGroupAction } from "@Services/ShippingGroupService"
import { NOTIFY_CANCEL } from "@Helpers/constHelper"
import { TransactionCodeEnum } from "@Enums/TransactionCodeEnum"
import { EShippinggroupAction } from "@Enums/shippinggroupEmun"
import { IOrder } from "@Interfaces/IOrder"
import { TypeVariation6 } from "@Types/CommonsTypes"
import { ResourcePermissionsEnum } from "@Enums/ResourcePermissionsEnum"
import useUserPermissions from "@Hooks/UseUserPermissions"

const UseDeliveredRejectOrder = ({
  order,
  shippingGroupId,
}: { order: IOrder | TypeVariation6, shippingGroupId: string } ) => {
  const { notification, errorHander } = useContext(GlobalContext)

  const [canIuseDeliveredReject, setCanIuseDeliveredReject] = useState(false);

  const [loadingDeliveredReject, setLoadingDeliveredReject] = useState(false)

  const history = useHistory()

  const { permission } = useUserPermissions({ resources: [ResourcePermissionsEnum.deliveredReject] });

  useEffect( () => {
    permission[ResourcePermissionsEnum.deliveredReject] && setCanIuseDeliveredReject(true) 
  }, [permission])

  const handleClickDeliveredRejectOrder = async (action: EShippinggroupAction) => {
    try {
        setLoadingDeliveredReject(true)
      const {
        data: { code },
      } = await executeShipingGroupAction({
        shippingGroupId,
        action,
        requestBody: {
            "comment": "REPROCESO",
            "custom": {
                "reason": "SINIESTRO"
            }
         },
      })

      setLoadingDeliveredReject(false)

      //picking success TODO:: case error
      if (code === TransactionCodeEnum.ok) {
        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: "Orden rechazada correctamente",
            title: NOTIFY_CANCEL,
            type: "success",
          },
        })

        setTimeout(() => history.push("/order/confirmation"), 2000)
      }
    } catch (error: any) {
      Logger.error(error)
      setLoadingDeliveredReject(false)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  return {
    handleClickDeliveredRejectOrder,
    loadingDeliveredReject,
    canIuseDeliveredReject
  }
}

export default UseDeliveredRejectOrder
