import { Suspense, useContext } from "react"
import SkeletonShippinGroupDetail from "@Components/Commons/Skeleton/SkeletonShippinGroupDetail"
import SummaryProductList from "@Components/OrderDetail/SummaryProducts/SummaryProductsList"
import { ResourcePermissionsEnum } from "@Enums/ResourcePermissionsEnum"
import OrderDetailHOC from "@Hoc/OrderDetailHOC"
import useUserPermissions from "@Hooks/UseUserPermissions"
import { IconSpinner } from "@Theme/common/Icon.styled"
import { OrderDetailType } from "@Types/OrderType"
import React from "react"
import TagPrintContent from "@Components/OrderDetail/TagPrint/TagPrintContent"
import { AuthContext } from "@Context/context/AuthContext"
import { clients } from "@Webapp-Clients/Clients"

const InfoElocker = React.lazy(() => import("@Components/OrderDetail/ElockerQR/InfoElocker"))

const CanceledScreen = ({ shippinGroupDetail, order, shippingGroupId }: OrderDetailType) => {
  const {
    state: { user },
  } = useContext(AuthContext)

  const client = clients[user?.mainGroup.id as keyof typeof clients]
  const FilesList = client["FilesList" as keyof typeof client]

  const itemsOrder = shippinGroupDetail?.source.items
  const elockerInfo = shippinGroupDetail?.custom?.infoElocker
  const documents = shippinGroupDetail?.custom?.documents

  const { permission } = useUserPermissions({ resources: [ResourcePermissionsEnum.qrcancel2] })
  return (
    <>
      {itemsOrder ? (
        <>
          <SummaryProductList
            itemsOrder={itemsOrder}
            groupPicking={false}
            titleMain={"Orden cancelada"}
          />
          {elockerInfo && elockerInfo.isElocker && (
            <Suspense
              fallback={
                <>
                  <IconSpinner variant="big" className="content-center" />
                  <p className="padding-content">Cargando información Elocker</p>
                </>
              }
            >
              <InfoElocker
                codigoCancelacion={
                  permission[ResourcePermissionsEnum.qrcancel2]
                    ? elockerInfo.codigoCancelacion
                    : null
                }
                puerta={elockerInfo.puerta}
              />
            </Suspense>
          )}
          {order && (
            <TagPrintContent
              codigoEntrega={(elockerInfo?.isElocker && elockerInfo?.codigoEntrega) || null}
              order={order}
              shippingGroupId={shippingGroupId}
            />
          )}
          {FilesList && (
            <Suspense fallback={null}>
              <FilesList documents={documents} channel={shippinGroupDetail?.channel} />
            </Suspense>
          )}
        </>
      ) : (
        <SkeletonShippinGroupDetail />
      )}
    </>
  )
}

export default OrderDetailHOC(CanceledScreen)
