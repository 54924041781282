import { memo, Suspense, useContext } from "react"
import { CardOrderItemStyled, ContentOrderItemstyled } from "@Components/Core/Order/CardOrder/CardOrderItem.styled"
import { IconArrowRigth, IconQrCode, IconIoStorefront } from "@Theme/common/Icon.styled"
import { ICardOrderItem } from "@Interfaces/IOrder"
import Checkbox from "@Components/Commons/CheckBox"
import { getValueShippinggroupStatusEnum } from "@Helpers/UtilsHelper"
import { AuthContext } from "@Context/context/AuthContext"
import { clients } from "@Webapp-Clients/Clients"
import { useTranslation } from "react-i18next"
import { EShippinggroupStatus } from "@Enums/shippinggroupEmun"

const CardOrderItem = memo(
  ({
    id,
    orderId,
    channel,
    salesChannelId,
    source,
    target,
    shippingType,
    currentStatus,
    canSelect = false,
    handleClickSelectOrder,
    isCheckedOrder,
    statusSG,
    custom,
    showStatus,
  }: ICardOrderItem) => {
    const { t } = useTranslation()
    const paramType = statusSG || getValueShippinggroupStatusEnum(currentStatus.tag)
    const {
      state: { user },
    } = useContext(AuthContext)
    const infoSgToPrint = { id, orderId, source, target }
    const TRANSIT = "transit"
    const TRANSIT_TO_SOURCE = "transit_to_source"

    const TagCustomOrder = user?.mainGroup?.id && clients[user.mainGroup.id as keyof typeof clients]?.TagCustomOrder

    // TODO: this needs refactor, should be removed asap
    const validateStatusTransitPrivilege = (): string => {
      let transitType: string = TRANSIT
      let isConsolidation: boolean = Boolean(custom?.consolidation) || false
      let toMySource: boolean = (user?.currentSources || []).includes(target?.source?.id)
      if (isConsolidation && toMySource) {
        transitType = TRANSIT_TO_SOURCE
      } else if (isConsolidation && !toMySource) {
        transitType = TRANSIT
      } else if (!isConsolidation && toMySource) {
        transitType = TRANSIT_TO_SOURCE
      } else if (!isConsolidation && !toMySource) {
        transitType = TRANSIT
      } else {
        transitType = TRANSIT
      }
      return transitType
    }

    const checkRouteStatusType = (stateUrl: any): string | EShippinggroupStatus => {
      if (currentStatus.frontTag) {
        return currentStatus.frontTag ? TRANSIT_TO_SOURCE : TRANSIT
      }

      if (currentStatus.aliasStatus) {
        return "in_transit_for_reception"
      }

      if (window?.$website === "privilege" && [TRANSIT, TRANSIT_TO_SOURCE].includes(stateUrl)) {
        return validateStatusTransitPrivilege()
      }
      return stateUrl
    }

    const renderStatus = (currentStatus: any): string => {
      let _currentStatus = currentStatus.tag

      if (!!currentStatus.frontTag) {
        _currentStatus = currentStatus.frontTag
      }

      if (!!currentStatus.aliasStatus) {
        _currentStatus = currentStatus.aliasStatus
      }

      return t(`${_currentStatus}.title`)
    }

    return (
      <>
        <ContentOrderItemstyled selected={isCheckedOrder}>
          {canSelect && (
            <div className="content-order-checked">
              <Checkbox
                id={id}
                name={id}
                onChange={(ev) => handleClickSelectOrder(ev, infoSgToPrint)}
                checked={isCheckedOrder}
              />
            </div>
          )}
          <CardOrderItemStyled to={`/order/${checkRouteStatusType(paramType)}/${id}/${orderId}`}>
            <div className="content-item-order">
              <span className="relevant-title item">{t(shippingType)}</span>
              <span className="item relevant-title">
                ID canal ventas
                <span className="relevant-title">{salesChannelId}</span>
              </span>
              <span className="item">
                ORDEN
                <span>{orderId}</span>
              </span>

              <span className="item">
                SG
                <span>{id}</span>
              </span>
              {source?.name && (
                <span className="item">
                  Source
                  <span>{source?.name}</span>
                </span>
              )}
              <span className="item">
                Canal
                <span>{channel}</span>
              </span>

              {!custom?.infoElocker?.isElocker && custom?.infoElocker?.codigoRetiro && (
                <p className="item">
                  <IconQrCode />
                  Código de retiro
                  <span>{custom?.infoElocker?.codigoRetiro}</span>
                </p>
              )}

              {showStatus && (
                <span className="item">
                  Estado
                  <span>
                    {renderStatus(currentStatus)}
                  </span>
                </span>
              )}
            </div>

            {TagCustomOrder && (
              <Suspense fallback={null}>
                <TagCustomOrder custom={custom} />
              </Suspense>
            )}

            {shippingType === "SP" ? <IconIoStorefront className="icon-storepickup-item" /> : null}
            <IconArrowRigth className="icon-arrow-rigth" />
          </CardOrderItemStyled>
        </ContentOrderItemstyled>
      </>
    )
  },
)

export default CardOrderItem
