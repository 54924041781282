import DashBordScreen from "@Pages/HomeScreens/HomeScreen/DashBoardScreen"
import OrderScreen from "@Pages/OrderScreens/OrderScreen"
import ProfileScreen from "@Pages/UserScreens/ProfileScreen/ProfileScreen"
import ReportPage from "@Pages/Report/ReportPage"
import ConfirmOrderScreen from "@Pages/DetailOrderScreens/Confirm/ConfirmOrderScreen"
import PackageScreen from "@Pages/DetailOrderScreens/Package/PackageScreen"
import DeliveryScreen from "@Pages/DetailOrderScreens/Delivery/DeliveryScreen"
import TransitScreen from "@Pages/DetailOrderScreens/Transit/TransitScreen"
import PickupScreen from "@Pages/DetailOrderScreens/Pickup/PickupScreen"
import DeliveredScreen from "@Pages/DetailOrderScreens/Delivered/DeliveredSreen"
import ErrorHandler from "@Components/Commons/Error/ErrorHandler"
import { IRouteComponent } from "@Interfaces/IRoute"
import { validateAccessRouteOrder } from "@Helpers/validationRoutesHelper"
import ClosedScreen from "@Pages/DetailOrderScreens/Closed/ClosedScreen"
import CanceledScreen from "@Pages/DetailOrderScreens/Canceled/CanceledScreen"
import SearchResultsScreen from "@Pages/SearchResults/SearchResultsScreen"
import { EShippinggroupStatus } from "@Enums/shippinggroupEmun"
import OrderDetailScreen from "@Pages/DetailOrderScreens/Custom/OrderDetailScreen"

const RouteListWom: IRouteComponent[] = [
  {
    path: "/",
    name: "DashBordScreen",
    component: DashBordScreen,
    exact: true,
  },
  {
    path: "/dashboard",
    name: "DashBordScreen",
    component: DashBordScreen,
    exact: true,
  },
  {
    path: "/profile",
    name: "ProfileScreen",
    component: ProfileScreen,
    exact: true,
  },
  {
    path: "/order/:type",
    name: "OrderPage",
    component: OrderScreen,
    exact: true,
    handleValidateParams: validateAccessRouteOrder,
    redirectPath: "/",
  },
  {
    path: ["/order/confirmation/:shippingGroupId/:id"],
    name: "oderDetailConfrim",
    component: ConfirmOrderScreen,
    exact: true,
    statusOrder: EShippinggroupStatus.confirmation,
  },
  {
    path: ["/order/package/:shippingGroupId/:id"],
    name: "oderDetailPackage",
    component: PackageScreen,
    exact: true,
    statusOrder: EShippinggroupStatus.package,
  },
  {
    path: ["/order/delivery/:shippingGroupId/:id"],
    name: "oderDetailDelivery",
    component: DeliveryScreen,
    exact: true,
    statusOrder: EShippinggroupStatus.delivery,
  },
  {
    path: ["/order/transit/:shippingGroupId/:id"],
    name: "oderDetailTransit",
    component: TransitScreen,
    exact: true,
    statusOrder: EShippinggroupStatus.transit,
  },
  
  {
    path: ["/order/in_transit_for_reception/:shippingGroupId/:id"],
    name: "oderDetailTransit",
    component: OrderDetailScreen,
    exact: true,
    statusOrder: EShippinggroupStatus.transit,
  },

  {
    path: ["/order/pickup/:shippingGroupId/:id"],
    name: "oderDetailPickup",
    component: PickupScreen,
    exact: true,
    statusOrder: EShippinggroupStatus.pickup,
  },
  {
    path: ["/order/delivered/:shippingGroupId/:id"],
    name: "oderDetailDelivered",
    component: DeliveredScreen,
    exact: true,
    statusOrder: EShippinggroupStatus.delivered,
  },
  {
    path: ["/order/closed/:shippingGroupId/:id"],
    name: "oderDetailClosed",
    component: ClosedScreen,
    exact: true,
    statusOrder: EShippinggroupStatus.closed,
  },
  {
    path: ["/order/canceled/:shippingGroupId/:id"],
    name: "oderDetailCanceled",
    component: CanceledScreen,
    exact: true,
    statusOrder: EShippinggroupStatus.canceled,
  },
  {
    path: ["/order/rejected/:shippingGroupId/:id"],
    name: "oderDetailRejected",
    component: CanceledScreen,
    exact: true,
    statusOrder: EShippinggroupStatus.rejected,
  },

  {
    path: "/search/:query/:optionFilter",
    name: "SearchResultsPage",
    component: SearchResultsScreen,
    exact: true,
  },

  {
    path: "/report",
    name: "ReportPage",
    component: ReportPage,
    exact: true,
  },

  {
    path: "*",
    name: "notFound",
    component: ErrorHandler,
    exact: false,
  },
]

export default RouteListWom
