import React, { Suspense, useContext, useEffect, useState } from "react"
import BackBotton from "@Components/Commons/BackBotton/BackBotton"
import { MaintitleStyled, TitlleSuccess } from "@Theme/common/Paragraph.styled"
import SkeletonThemeContainer from "@Components/Commons/Skeleton/SkeletonThemeContainer"
import useGetOrderDetail from "@Hooks/UseGetOrderDetail"
import IPage from "@Interfaces/IPage"
import { OrderDetailType, OrderDetailParams } from "@Types/OrderType"
import { RouteComponentProps } from "react-router-dom"
import Logger from "@Classes/Logger"
import HeaderDetail from "@Components/OrderDetail/HeaderDetail/HeaderDetail"
import HomeBotton from "@Components/Commons/HomeBotton/HomeBotton"
import AlertStyled from "@Theme/common/Alert.styled"
import { IconQrCode } from "@Theme/common/Icon.styled"
import Skeleton from "react-loading-skeleton"
import { AuthContext } from "@Context/context/AuthContext"
import { WebAppClientsEnum } from "@Enums/CommonEnum"
import { clients } from "@Webapp-Clients/Clients"

const OrderDetailHOC = (Component: React.ComponentType<OrderDetailType>) => {
  const WrapperOrderDetail = (props: IPage & RouteComponentProps<OrderDetailParams>) => {
    const {
      state: { user },
    } = useContext(AuthContext)

    const TagConsolidation =
      user?.mainGroup?.id === WebAppClientsEnum.privilege && clients.privilege.TagConsolidation

    const {
      name,
      match: {
        params: { id, shippingGroupId },
      },
    } = props

    const [finishedShippingGroupAction, setFinishedShippingGroupAction] = useState(false)

    const { shippingGroupDetail, order } = useGetOrderDetail(id, shippingGroupId)

    const infoElocker = shippingGroupDetail?.custom?.infoElocker

    useEffect(() => {
      Logger.info(`Loading ${name}`)
    }, [name])

    return (
      <SkeletonThemeContainer>
        <BackBotton>
          <MaintitleStyled>{`# ${shippingGroupId}`}</MaintitleStyled>
        </BackBotton>
        {order?.id ? (
          <AlertStyled variant="info">
            <p className="relevant-title">Orden {order.id}</p>
          </AlertStyled>
        ) : (
          <Skeleton width={"100%"} height={25} style={{ lineHeight: "unset" }} />
        )}
        <HeaderDetail order={order} sg={shippingGroupDetail} />

        {TagConsolidation && (
          <Suspense fallback={<Skeleton width={"100%"} height={25} />}>
            <TagConsolidation consolidation={order?.custom?.consolidation} />
          </Suspense>
        )}

        {finishedShippingGroupAction && (
          <>
            <TitlleSuccess>El ShippingGroup fue actualizado correctamente.</TitlleSuccess>
            <HomeBotton />
          </>
        )}

        {shippingGroupDetail?.source?.name ? (
          <AlertStyled className="m-top-button1" variant="info">
            <p>Source {shippingGroupDetail.source.name}</p>
          </AlertStyled>
        ) : (
          <Skeleton className="m-top-button1" width={"100%"} height={25} />
        )}

        {!infoElocker?.isElocker && infoElocker?.codigoRetiro && (
          <AlertStyled className="m-top-button1" variant="info">
            <IconQrCode /> <p>Código de retiro {infoElocker.codigoRetiro}</p>
          </AlertStyled>
        )}

        <Component
          order={order}
          shippinGroupDetail={shippingGroupDetail}
          shippingGroupId={shippingGroupId}
          finishedShippingGroupAction={finishedShippingGroupAction}
          setFinishedShippingGroupAction={setFinishedShippingGroupAction}
        />
      </SkeletonThemeContainer>
    )
  }

  return WrapperOrderDetail
}

export default OrderDetailHOC
