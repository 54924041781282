import { useState, useContext } from "react"
import { GlobalContext } from "@Context/context/GlobalContext"
import { attachFileShippingGroup, executeShipingGroupAction } from "@Services/ShippingGroupService"
import Logger from "@Classes/Logger"
import { TransactionCodeEnum } from "@Enums/TransactionCodeEnum"
import { NOTIFY_PICKED, NOTIFY_DELIVERED } from "@Helpers/constHelper"
import { ProcessShippingGroupActionType } from "@Types/OrderType"
import { EShippinggroupAction } from "@Enums/shippinggroupEmun"
import useUserPermissions from "@Hooks/UseUserPermissions"
import { ResourcePermissionsEnum } from "@Enums/ResourcePermissionsEnum"
import { FilesFormType } from "@Types/FilesFormType"

const usePickupOrder = ({
  setFinishedShippingGroupAction,
  shippingGroupId,
  order,
  isElocker,
  shippinGroupDetail,
}: ProcessShippingGroupActionType ) => {
  const [loadingPickup, setLoadingPickup] = useState(false)
  const [loadingCancel, setLoadingCancel] = useState(false)

  const { notification, errorHander } = useContext(GlobalContext)

  const { permission } = useUserPermissions({
    resources: [
      ResourcePermissionsEnum.pickupElocker,
      ResourcePermissionsEnum.cancelOrder,
      ResourcePermissionsEnum.qrEntryCancel,
      ResourcePermissionsEnum.qrExitCancel,
      ResourcePermissionsEnum.orderPickupCancel,
    ],
  })

  const attachFile = async (filesShippinGroup: FileList): Promise<boolean> => {
    const formAttachFile = new FormData()

    for (let indexFile = 0; indexFile < filesShippinGroup.length; indexFile++) {
      const file = filesShippinGroup[indexFile]
      if (file) formAttachFile.append(`$attach${indexFile}`, file)
    }

    formAttachFile.append("tags", [...filesShippinGroup].map(({ name }) => name).join(","))
    const {
      data: { code },
    } = await attachFileShippingGroup(shippingGroupId, formAttachFile)

    return code === TransactionCodeEnum.ok
  }

  const handleClickPickupActionWithFiles = async ({ filesShippinGroup }: FilesFormType) => {
    try {
      setLoadingPickup(true)

      let successAttachFiles: boolean = true
      if (filesShippinGroup) {
        successAttachFiles = await attachFile(filesShippinGroup)
      }
      if (!successAttachFiles) {
        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: "Ha ocurrido un error, al cargar los archivo",
            title: NOTIFY_DELIVERED,
            type: "danger",
          },
        })
        return
      }
      await executeAction(EShippinggroupAction.picked_up, "Productos entregado correctamente")
      setLoadingPickup(false)
    } catch (error: any) {
      Logger.error(error)
      setLoadingPickup(false)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  const handleClickPickupAction = async () => {
    try {
      setLoadingPickup(true)
      await executeAction(EShippinggroupAction.picked_up, "Productos entregado correctamente")
      setLoadingPickup(false)
    } catch (error: any) {
      Logger.error(error)
      setLoadingPickup(false)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  const handleClickCancelAction = async () => {
    try {
      setLoadingCancel(true)
      await executeAction(EShippinggroupAction.cancel, "Orden cancelada correctamente")
      setLoadingCancel(false)
    } catch (error: any) {
      Logger.error(error)
      setLoadingCancel(false)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  const executeAction = async (action: EShippinggroupAction, messageResul: string) => {
    const {
      data: { code },
    } = await executeShipingGroupAction({
      shippingGroupId,
      action,
    })

    if (code === TransactionCodeEnum.ok) {
      setFinishedShippingGroupAction(true)

      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: messageResul,
          title: NOTIFY_PICKED,
          type: "success",
        },
      })
    }
  }

  const canCancelOrder = () => {
    if (window.$website === "wom") {
      return permission[ResourcePermissionsEnum.orderPickupCancel]
    } else {
      return permission[ResourcePermissionsEnum.cancelOrder]
    }
  }

  const shouldBeFilePresentOnSubmit = () => {
    if (order?.deliveryType === "ED" && (!shippinGroupDetail?.custom?.infoElocker || !shippinGroupDetail?.custom?.infoElocker?.isElocker)) {
      return true
    }
    return false
  }

  return {
    handleClickPickupAction,
    handleClickCancelAction,
    handleClickPickupActionWithFiles,
    shouldBeFilePresentOnSubmit,
    loadingPickup,
    loadingCancel,
    hasPermissionQREntry: permission[ResourcePermissionsEnum.qrEntryCancel],
    hasPermissionQRExitCancel: permission[ResourcePermissionsEnum.qrExitCancel],
    denyPickupAction: isElocker && !permission[ResourcePermissionsEnum.pickupElocker],
    canCancelOrder: canCancelOrder(),
  }
}

export default usePickupOrder
