import { wom } from "@Webapp-Clients/Wom"
import { privilege } from "@Webapp-Clients/PRVL"
import { sandbox } from "@Webapp-Clients/Sbx"
import { sodimac } from "@Webapp-Clients/SDM"

export const clients = {
  wom,
  privilege,
  sandbox,
  sodimac
}
