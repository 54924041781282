import i18n from "i18next"
import translationDefault from "./default/translation.json"
import translationWom from "./wom/translation.json"
import translationPrivilige from "./privilege/translation.json"
import { initReactI18next } from "react-i18next"
import { getWebsite } from "@Helpers/websiteHelper"

export const resources = {
  default: {
    translation: translationDefault,
  },
  wom: {
    translation: translationWom,
  },
  privilege: {
    translation: translationPrivilige,
  },
}

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "default",
  lng: getWebsite(),
})

export default i18n
