import { IRadioButton } from "@Interfaces/IRadioButton"
import { InputRadio, Label, Mark, RadioWrapper } from "@Theme/common/RadioButton"

const RadioButton: React.FC<IRadioButton> = ({ name, checked, value,onChange, children }) => (
  <RadioWrapper>
    <Label>
      <InputRadio name={name} value={value} checked={checked} onChange={onChange} />
      <Mark />
      {children}
    </Label>
  </RadioWrapper>
)

export default RadioButton
