import axiosClient from "@Config/axiosClient"
import { ICollection, IResponseService } from "@Interfaces/IResponseService"
import configEnv from "@Config/configEnv"
import { AxiosResponse, CancelToken } from "axios"
import { DEFAULT_CHANNEL } from "@Helpers/constHelper"
import { IItems } from "@Interfaces/IItemsOrder"

const axiosClientOIM = axiosClient("OIM")

export const getItemsQuantity = async (
  skus: Array<string>,
  cancelToken: CancelToken,
  channel: string = DEFAULT_CHANNEL,
): Promise<AxiosResponse<IResponseService<ICollection<IItems>>>> => {
  const client = axiosClientOIM.getClient()

  const url = `${configEnv.urlItems}/all`

  const body = { channel: channel, maxRecords: 1000, skipRecords: 0, filter: { sku: skus } }
  const response = await client.post<IResponseService<ICollection<IItems>>>(url, body, {
    headers: { "Content-Type": "application/json" },
    cancelToken,
  })

  return response
}
