import { lazy } from "react"
import { ComponentsType } from "@Types/CommonsTypes"

const ManualTransition = lazy(() => import("@Components/Core/ProductTransition/ManualTransition"))

const FileUpload = lazy(() => import("@Components/Commons/FileUpload/FileUpalder"))

const ContentFileMedia = lazy(
  () => import("@Webapp-Clients/Wom/Components/ViewContentMedia/ContentFileMedia"),
)

export const sandbox: ComponentsType = {
  ContentFileMedia,
  ProductTransition: ManualTransition,
  FileUpload
}
