import { useContext } from "react"
import { Route, RouteComponentProps, Switch, Redirect } from "react-router-dom"
import RouteListDefault from "@Theme/assets/Data/RouteList"
import RouteListWom from "@overrides/theme/wom/base/routes/List/RouteList"
import RouteListSandbox from "@themesandbox/base/Routes/List/RouteList"
import { AuthContext } from "@Context/context/AuthContext"
import { EShippinggroupStatus } from "@Enums/shippinggroupEmun"

const DashboardRouter = () => {
  const {
    state: { optionsMenuAccess },
  } = useContext(AuthContext)

  const authState = useContext(AuthContext)
  const user = authState?.state.user
  const group = user?.mainGroup.id

  let customRoutes = [
    "pickup_in_my_source",
    "transit_to_source"
  ]  

  const getClientConfig = (group: string = "") => {
    switch(group) {
      case "wom": 
        return RouteListWom
      case "sandbox":
        return RouteListSandbox
      default: 
        return RouteListDefault
    }
  }  

  let RouteList = getClientConfig(group)

  // TODO: This could be refactorized
  const isACustomRoute = (route: any): boolean => {
    if ([
      EShippinggroupStatus.transit_source,
      EShippinggroupStatus.pickup_in_my_source
    ].includes(route.statusOrder)) {      
      let path = route.path[0]
      let result = customRoutes.some(option => {
        return path.includes(option)
      })
      return result
    }
    return false
  }

  return (
    <Switch>
      {RouteList.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            render={(props: RouteComponentProps<any>) => {
              if (route.statusOrder) {
                const hasPermission = optionsMenuAccess.some(
                  (option) => {
                    if (isACustomRoute(route)) return true // TODO: This could be refactorized
                    return option.statusOrder === route.statusOrder
                  });
                if (!hasPermission) return <Redirect to={"/"} />
              }

              if (!route.handleValidateParams)
                return <route.component {...props} {...route.props} name={route.name} />

              const validation = route.handleValidateParams(props, optionsMenuAccess)
              return validation ? (
                <route.component {...props} {...route.props} name={route.name} />
              ) : (
                <Redirect to={route.redirectPath || "/"} />
              )
            }}
          />
        )
      })}
    </Switch>
  )
}

export default DashboardRouter
