import { useState, useReducer } from "react"
import { IContext } from "@Interfaces/IContext"
import { GlobalContext } from "@Context/context/GlobalContext"
import { NotificationReducer } from "@Context/reducers/NotificationReducer"
import { IErrorHandler } from "@Interfaces/IErrorHandler"

/**
 * GlobalProvider this is the global context of services
 * @param {children} type IContext 
 * @return {Element} of type GlobalContext.Provider
 */
const GlobalProvider = ({ children }: IContext) => {
  const [showUserProfileHeader, setShowUserProfileHeader] = useState(false)
  const [notifcations, setNotifications] = useReducer(NotificationReducer, [])
  const [error, setError] = useState<IErrorHandler>({ hasError: false })

  const dataProvider = {
    // It is to know if it is in the path of the user information in the header
    userProfileHeader: {
      state: showUserProfileHeader,
      dispatch: setShowUserProfileHeader,
    },
    // this is Notifications for the user
    notification: {
      state: notifcations,
      dispatch: setNotifications,
    },
    //this is to handle global bugs
    errorHander: {
      state: error,
      dispatch: setError,
    },
  }

  return <GlobalContext.Provider value={dataProvider}>{children}</GlobalContext.Provider>
}

export default GlobalProvider
