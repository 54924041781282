import Skeleton from "react-loading-skeleton"
import { GridContainer } from "@Theme/common/Grid.styled"

const SkeletonGroupOrder = ({ count }: { count: number }) => {
  return (
    <>
      {Array(count)
        .fill(0)
        .map((_, index) => (
          <div className="content-group" key={index}>
            <div className="group-item">
              <Skeleton count={1} height={26} width={210} style={{ margin: "1rem 0" }} />
            </div>
            <GridContainer>
              <Skeleton count={1} height={115}></Skeleton>
              <Skeleton count={1} height={115}></Skeleton>
              <Skeleton count={1} height={115}></Skeleton>
            </GridContainer>
          </div>
        ))}
    </>
  )
}

export default SkeletonGroupOrder
