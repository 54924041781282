import { displays } from "@Theme/base/Variables"
import styled from "styled-components"

const ContentCalendarStyled = styled.section`
  padding: 1.5rem 1rem 0.5rem 1rem;
  margin: 1rem;
  background: ${({ theme }) => theme.palette.white};
  border-radius: 8px;

  & .content-option-calendar {
    ${displays.flexBase};
    gap: 0.2rem;
  }

  & .content-calendar-body {
    ${displays.flexColumn};

    & > button {
      margin-bottom: 1rem;
      width: 100%;
    }
  }
`

export default ContentCalendarStyled
