import SummaryProductList from "@Components/OrderDetail/SummaryProducts/SummaryProductsList"
import OrderDetailHOC from "@Hoc/OrderDetailHOC"
import { OrderDetailType } from "@Types/OrderType"
import useOrderAction from "@themesandbox/base/Hooks/Order/useOrderAction"
import Action from "@themesandbox/base/Components/Order/Detail/Action"
import SkeletonShippinGroupDetail from "@Components/Commons/Skeleton/SkeletonShippinGroupDetail"
import TagPrintContent from "@Components/OrderDetail/TagPrint/TagPrintContent"
import { useEffect } from "react"
import useHideActionButtons from "@Hooks/UseHideActionButtons"
import { IconCancel } from "@Theme/common/Icon.styled"
import { ButtonStyled } from "@Theme/common/Button.styled"
import ContentDetailOrderActionsStyled from "@Theme/common/ContentDetailOrderActions.styled"
import { EShippinggroupAction } from "@Enums/shippinggroupEmun"
import useCancelDecreaseOrder from "@Hooks/UseCancelDecreaseOrder"
import UseDeliveredRejectOrder from "@Hooks/UseDeliveredRejectOrder"
import UseCancelOrder from "@Hooks/UseCancelOrder"
import { useGetCancelActionByClient } from "@Hooks/UseGetMultiClient"

// Cloned from TransitScreen
import useFormConfig from "@themesandbox/base/Hooks/Media/useFormConfig"

const OrderDetailScreen = ({
  shippinGroupDetail,
  shippingGroupId,
  setFinishedShippingGroupAction,
  finishedShippingGroupAction,
  order,
}: OrderDetailType) => { 
  const { hideTransitActions, updateVisibilityButtonActions } = useHideActionButtons()

  useEffect(() => {
    updateVisibilityButtonActions(
      shippinGroupDetail?.source?.id,
      shippinGroupDetail?.target?.source?.id,
    )
  }, [shippinGroupDetail, updateVisibilityButtonActions])

  const itemsOrder = shippinGroupDetail?.source.items
  const elockerInfo = shippinGroupDetail?.custom?.infoElocker

  // TODO: multiclient
  // Refactor this, ovverride component needed
  const { handleSubmit } = useFormConfig()

  const { submitDeliveredOrder, loadingTransit } = useOrderAction({
    shippingGroupId,
    setFinishedShippingGroupAction,
    order,
    shippinGroupDetail
  })

  const { handleClickCancelDecreaseOrder, loadingCancelDecrase, canIuseCancelDecraese } =
    useCancelDecreaseOrder({
      order,
      shippingGroupId,
    })

  const { handleClickDeliveredRejectOrder, loadingDeliveredReject, canIuseDeliveredReject } =
    UseDeliveredRejectOrder({
      order,
      shippingGroupId,
    })

  const handleClickCancelDecrease = () => {
    handleClickCancelDecreaseOrder(EShippinggroupAction.cancel_decrease)
  }

  const handleClickDeliveredReject = () => {
    handleClickDeliveredRejectOrder(EShippinggroupAction.delivered_reject)
  }

  const [permissionOrder, actionOrder] = useGetCancelActionByClient()

  const handleClickCancel = () => {
    handleClickCancelOrder(actionOrder)
  }

  const { handleClickCancelOrder, loadingCancel, canIuseCancel } = UseCancelOrder({
    order,
    shippingGroupId,
    permissionOrder,
  })

  return (
    <>
      
      {itemsOrder ? (
        <>
          {false && canIuseCancel && (
            <ContentDetailOrderActionsStyled>
              <ButtonStyled
                variant="secondary"
                onClick={handleClickCancel}
                loadingButton={loadingCancel}
                disabled={loadingCancel}
              >
                <IconCancel />
                <span>Cancelar Orden</span>
              </ButtonStyled>
            </ContentDetailOrderActionsStyled>
          )}

          <SummaryProductList
            itemsOrder={itemsOrder}
            groupPicking={false}
            titleMain={finishedShippingGroupAction ? "Productos en tránsito" : undefined}
          />
          {!finishedShippingGroupAction && (
            <>
              <form onSubmit={handleSubmit(submitDeliveredOrder)}>               

                {false && canIuseCancelDecraese && (
                  <ContentDetailOrderActionsStyled>
                    <ButtonStyled
                      variant="secondary"
                      onClick={handleClickCancelDecrease}
                      loadingButton={loadingCancelDecrase}
                      disabled={loadingCancelDecrase}
                    >
                      <IconCancel />
                      <span>Cancelar Orden sin Modificar Inventario</span>
                    </ButtonStyled>
                  </ContentDetailOrderActionsStyled>
                )}

                {false && canIuseDeliveredReject && (
                  <ContentDetailOrderActionsStyled>
                    <ButtonStyled
                      variant="secondary"
                      onClick={handleClickDeliveredReject}
                      loadingButton={loadingDeliveredReject}
                      disabled={loadingDeliveredReject}
                    >
                      <IconCancel />
                      <span>Cancelar orden por siniestro</span>
                    </ButtonStyled>
                  </ContentDetailOrderActionsStyled>
                )}

                {!hideTransitActions && <Action loadingTransit={loadingTransit} />}
              </form>
            </>
          )}
          {order && (
            <TagPrintContent
              codigoEntrega={(elockerInfo?.isElocker && elockerInfo?.codigoEntrega) || null}
              order={order}
              shippingGroupId={shippingGroupId}
            />
          )}          
        </>
      ) : (
        <SkeletonShippinGroupDetail />
      )}
    </>
  )
}

export default OrderDetailHOC(OrderDetailScreen)
