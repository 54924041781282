import { FunctionComponent } from "react"
import { Route, Redirect } from "react-router-dom"
import { IRoute } from "@Interfaces/IRoute"

const PrivateRouteComponent: FunctionComponent<IRoute> = (props: any) => {
  if (props.isAuthenticated) {
    return <Route {...props} />
  } else {
    const renderComponent = () => <Redirect to={{ pathname: props.redirectPath }} />
    return <Route {...props} component={renderComponent} render={undefined} />
  }
}

export default PrivateRouteComponent
