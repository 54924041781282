import { lazy, Suspense, useContext } from "react"
import ProductOrderDetailStyled, {
  ProductInfoDetail,
} from "@Components/OrderDetail/ProductOrderConfirm/ProductOrderDetail.styled"
import { IconCancel, IconSpinner } from "@Theme/common/Icon.styled"
import { ButtonStyled } from "@Theme/common/Button.styled"
import Skeleton from "react-loading-skeleton"
import { IProductOrderDetail } from "@Interfaces/IProductOrderDetail"
import { clients } from "@Webapp-Clients/Clients"
import { AuthContext } from "@Context/context/AuthContext"
import useUserPermissions from "@Hooks/UseUserPermissions"
import { ResourcePermissionsEnum } from "@Enums/ResourcePermissionsEnum"

import ProductDetail from "@Components/Core/ProductBase/ProductDetail"
import { useGetProductInfoByClient } from "@Hooks/UseGetMultiClient"

const ProductOrderDetail = ({ itemOrder, onClicProcessProduct, source, hideConfirmActions }: IProductOrderDetail) => {
  const Img = lazy(() => import("@Components/Commons/Image/Image"))

  const {
    state: { user },
  } = useContext(AuthContext)

  const fieldProduct = useGetProductInfoByClient(itemOrder)
  const { permission } = useUserPermissions({ resources: [
    ResourcePermissionsEnum.rejecProduct,
  ] })

  const ProductTransition =
    user?.mainGroup?.id && clients[user.mainGroup.id as keyof typeof clients]?.ProductTransition

  //events
  const handleClickReject = () => {
    if (itemOrder) {
      onClicProcessProduct({
        ...itemOrder,
        isConfirm: false,
      })
    }
  }

  return (
    <>
      <ProductOrderDetailStyled>
        <div className="content-info-product">
          <p className="product-name ">{itemOrder?.name || <Skeleton width={200} />}</p>
          <ProductInfoDetail>
            {itemOrder?.custom?.thumbnail && (
              <Suspense fallback={null}>
                <Img alt={itemOrder?.name} url={itemOrder?.custom?.thumbnail} />
              </Suspense>
            )}
            <div className="info">
              <ProductDetail fields={fieldProduct} />
            </div>
          </ProductInfoDetail>

          {ProductTransition && !hideConfirmActions && (
            <Suspense fallback={<IconSpinner variant="big" className="content-center" />}>
              <ProductTransition
                itemOrder={itemOrder}
                onClicProcessProduct={onClicProcessProduct}
                source={source}
              />
            </Suspense>
          )}
          {permission[ResourcePermissionsEnum.rejecProduct] && !hideConfirmActions && (
            <>
              {itemOrder ? (
                <ButtonStyled variant="secondary" onClick={handleClickReject}>
                  <IconCancel />
                  <span>Rechazar producto</span>
                </ButtonStyled>
              ) : (
                <Skeleton width={"100%"} count={1} height={44} />
              )}
            </>
          )}
        </div>
      </ProductOrderDetailStyled>
    </>
  )
}

export default ProductOrderDetail
