import { FunctionComponent, useEffect, useContext } from "react"
import NotificationList from "@Components/Commons/Notifications/NotificationList"
import { GlobalContext } from "@Context/context/GlobalContext"
import Logger from "@Classes/Logger"
import SideBar from "@Components/Theme/Sidebar/SideBar"
import ErrorHandler from "@Components/Commons/Error/ErrorHandler"
import { MainContent } from "./MainLayout.styled"

const MainLayout: FunctionComponent = (props: any) => {
  const { children } = props

  const { errorHander, notification } = useContext(GlobalContext)

  const notifications = notification?.state
  const {
    type: { name },
  } = children

  useEffect(() => {
    Logger.info(`Loading ${name}`)
  }, [name])

  if (errorHander?.state?.hasError)
    return <ErrorHandler code={errorHander.state.code} message={errorHander?.state?.message} />

  return (
    <>
      {notifications && notifications.length > 0 && (
        <NotificationList notifications={notifications} />
      )}
      <SideBar />
      <MainContent>{children}</MainContent>
    </>
  )
}

export default MainLayout
