import { useContext } from "react"
import { GlobalContext } from "@Context/context/GlobalContext"
import { useLocation } from "react-router-dom"
import { ROUTE_ORDER } from "@Helpers/constHelper"
import { AuthContext } from "@Context/context/AuthContext"
import HeaderStyle from "@Components/Theme/Header/Header.styled"
import UserInfoProfile from "@Components/User/Profile/UserInfo/UserInfoProfile"
import { LinkIconStyled } from "@Theme/common/Link.styled"
import { IconBarsMenu, IconUser } from "@Theme/common/Icon.styled"
import logo from "@Theme/assets/img/ico_header_logo.svg"
import { summaryOptions } from "@Theme/assets/Data/SummaryOptionsData"
import { useMediaPredicate } from "react-media-hook"
import { breakpointsMedia } from "@Theme/base/Variables"
import LogoClient from "@Components/Commons/BrandClient/LogoClient"
import { useTranslation } from "react-i18next"
import { ILocationState } from "@Interfaces/IMenu"

const Header = ({ onShowSidebar }: { onShowSidebar: () => void }) => {
  const { t } = useTranslation()

  const location = useLocation<ILocationState>()
  const { userProfileHeader } = useContext(GlobalContext)

  const biggerThanMobile = useMediaPredicate(`(min-width: ${breakpointsMedia.sm})`)

  const authState = useContext(AuthContext)
  const user = authState?.state.user

  const renderCurrentPath = () => {
    const pathLocation = location.pathname
    let fromTransitToSource = location.state?.fromTransitToSource

    if (!pathLocation.includes(ROUTE_ORDER)) return null

    const menuSubItemOrder = summaryOptions.find(({ path, stateLocation }) => {
      // TODO: This should be refactorized asap
      let customRoute = ["pickup_in_my_source", "transit_to_source"].some(option => {
        return pathLocation.includes(option)
      })
      if (customRoute) {
        fromTransitToSource = true
      }
      // ----
      return pathLocation.includes(path) && (!fromTransitToSource || stateLocation)
    })

    return (
      menuSubItemOrder && (
        <div className="path-current">
          {menuSubItemOrder.icon} <span>{`${t(`${menuSubItemOrder.status}.subTitle`)}`}</span>
        </div>
      )
    )
  }

  return (
    <HeaderStyle showProfileUser={userProfileHeader?.state || false}>
      <div className="options-header">
        <div className="section-icon-menu">
          <LinkIconStyled to="#">
            <IconBarsMenu onClick={onShowSidebar} />
          </LinkIconStyled>

          <LogoClient
            render={(logo: JSX.Element) => <LinkIconStyled to="/dashboard">{logo}</LinkIconStyled>}
          />
        </div>
        {renderCurrentPath()}
        <div className="icon-user">
          {biggerThanMobile && user?.name && <p>{user.name}</p>}
          <LinkIconStyled to="/dashboard">
            <img src={logo} alt="logo omnix" />
          </LinkIconStyled>
          <LinkIconStyled to="/profile">
            <IconUser />
          </LinkIconStyled>
        </div>
      </div>

      {userProfileHeader?.state && user && (
        <UserInfoProfile name={user.name} rol={user.role} sources={user.currentSources} />
      )}
    </HeaderStyle>
  )
}

export default Header
