import website from "@Website/index"
import Env from "@Classes/Env"

interface IClients {
  [key: string]: string[]
}

const shippingTypesByClient = () => {
  let clients: IClients = {
    core: Env.theme.core.sg.sg_shipping_types,
    wom: ["HD", "SP"],
    privilege: ["HD", "SP"],
  }
  
  let result = clients.hasOwnProperty(website) ? (clients)[website] : clients.core

  return result
}

export default shippingTypesByClient;