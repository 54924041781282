import { OptionsFilterEnum } from "@Enums/OptionsFilterEnum"
import { IFilterOrder } from "@Interfaces/IFilterOrder"

export const filterOrderData: Array<IFilterOrder> = [
  {
    value: OptionsFilterEnum.query,
    label: "Id orden o SG",
  },
  {
    value: OptionsFilterEnum["custom.customer.name"],
    label: "Nombre cliente",
  },
  {
    value: OptionsFilterEnum["custom.customer.id"],
    label: "RUT",
  },
  {
    value: OptionsFilterEnum["source.name"],
    label: "Source",
  },
]
