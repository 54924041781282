import { WEB_APP_USER } from "@Helpers/constHelper"
import { IAuth } from "@Interfaces/IUser"

/**
 * Constant AuthReduceInit return an Array if userStorage exist
 * save user information to local storage 
 * If Exist
 * @return {Boolean} loading. Return true or false 
 * @return {null} error. Return null
 * @return {JSON} Json. info of user 
 * Else
 * @return {Boolean} loading. Return true or false 
 * @return {null} error. Return null
 * @return {null} user. Return null if UserStorage is null
 * @return {Array} optionsMenuAccess. return an empti array 
 */
export const AuthReducerInit = (): IAuth => {
  const userStorage: string = localStorage.getItem(WEB_APP_USER) || "{}"
  if (userStorage.match(/user/)) {
    return {
      loading: false,
      error: null,
      ...JSON.parse(userStorage),
    }
  } else {
    return {
      loading: false,
      user: null,
      optionsMenuAccess: [],
      error: null,
    }
  }
}
