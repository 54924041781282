import styled from "styled-components"
import { textuppercase } from "@Theme/base/Variables"

export const GroupItemStyled = styled.p`
  width: 210px;
  height: 26px;
  background: ${({ theme }) => theme.palette.grey20};
  color: ${({ theme }) => theme.palette.grey90};
  font-size: 0.786rem;
  font-weight: 500;
  ${textuppercase};
  text-align: center;
  border-radius: 4px;
  margin: 1rem 0;
  line-height: 2.4;
`

export const MaintitleStyled = styled.p`
  color: ${({ theme }) => theme.palette.grey60};
  font-size: 1.286rem;
  font-weight: 700;
  margin: 0 auto;
`

export const SecondtitleStyled = styled(MaintitleStyled)`
  font-size: 1rem;
  text-align: center;
`

export const SubTitleStyled = styled.p`
  background: ${({ theme }) => theme.palette.white};
  text-align: center;
  padding: 0.7rem;
  color: ${({ theme }) => theme.palette.grey60};
  border-radius: 7px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  margin: 1rem;
`
export const TitlleSuccess = styled.p`
  font-weight: 700;
  color: ${({ theme }) => theme.alert.success.backgroundColor};
  text-align: center;
  margin: 1.5rem 0;
`

export const ErrorFieldForm = styled.p`
  color: ${({ theme }) => theme.alert.danger.backgroundColor};
  font-weight: 700;
  text-align: center;
  margin: 0.5rem 0;
`
