import ProcessProductCard from "@Components/OrderDetail/ProcessProducts/ProcessProductCard/ProcessProductCard"
import { IProcessProductsList } from "@Interfaces/IProcessProductsList"
import { GridContainer } from "@Theme/common/Grid.styled"

const ProcessProductsList = ({ processItems = [], onClickEditItem }: IProcessProductsList) => {
  return (
    <GridContainer className="padding-content" minSize={"320px"}>
      {processItems.map(({ sku, isConfirm, name, custom, quantity }) => (
        <ProcessProductCard
          key={sku}
          isConfirm={isConfirm}
          name={name}
          sku={sku}
          custom={custom}
          quantity={quantity}
          onClickEditItem={onClickEditItem}
        />
      ))}
    </GridContainer>
  )
}

export default ProcessProductsList
