/**
 * Methods to work repetitive operations on the entire site
 *
 * @version 1.0.0
 * @author Naldo Duran<naldorck@gmail.com>
 */

export default class Utils {
  /**
   * Returns a value within a array
   *
   * @version 1.0.1
   * @author Naldo Duran<naldorck@gmail.com>
   * @param {Array<string|number>} arr
   * @param {string} value
   * @returns {Array}
   */
  public static getValueFromArray = (arr: any[], value: string) => (arr ? arr.filter((key) => value === key) : [])
}
