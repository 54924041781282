import { INotification } from "@Interfaces/INotification"
import { NotificationActionsType } from "@Context/actionsTypes/NotificationActionsType"
/**
 * is the notifications handler
 * @param state notification status
 * @param action notification type
 * @return {Array}  state, id, action
 */
export const NotificationReducer = (
  state: Array<INotification>,
  action: NotificationActionsType,
): Array<INotification> => {
  switch (action.type) {
    case "ADD_NOTIFICATION":
      return [...state, { id: state.length, ...action.payload }]

    case "DELETE_NOTIFICATION":
      return state.filter(({ id }) => id !== action.payload)

    default:
      return state
  }
}
