import { useEffect, useState, useContext } from "react"
import { useHistory } from "react-router-dom"
import { GlobalContext } from "@Context/context/GlobalContext"
import Logger from "@Classes/Logger"
import { executeShipingGroupAction } from "@Services/ShippingGroupService"
import { NOTIFY_CANCEL } from "@Helpers/constHelper"
import { TransactionCodeEnum } from "@Enums/TransactionCodeEnum"
import { EShippinggroupAction } from "@Enums/shippinggroupEmun"
import { IOrder } from "@Interfaces/IOrder"
import { TypeVariation6 } from "@Types/CommonsTypes"
import useUserPermissions from "@Hooks/UseUserPermissions"

const UseCancelOrder = ({
  order,
  shippingGroupId,
  permissionOrder,
}: { order: IOrder | TypeVariation6, shippingGroupId: string, permissionOrder: string, } ) => {
  
  const { notification, errorHander } = useContext(GlobalContext)

  const [canIuseCancel, setCanIuseCancel] = useState(false);

  const [loadingCancel, setLoadingCancel] = useState(false)

  const history = useHistory()

  const { permission } = useUserPermissions({ resources: [
    permissionOrder
  ] })

  useEffect( () => {
    permission[permissionOrder] && setCanIuseCancel(true)
    //eslint-disable-next-line
  }, [permission])

  const handleClickCancelOrder = async (action: EShippinggroupAction) => {
    try {
      setLoadingCancel(true)
      const {
          data: { code },
      } = await executeShipingGroupAction({
          shippingGroupId,
          action, 
      })

      setLoadingCancel(false)

      //picking success TODO:: case error
      if (code === TransactionCodeEnum.ok) {
        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: "Orden rechazada correctamente",
            title: NOTIFY_CANCEL,
            type: "success",
          },
        })

        setTimeout(() => history.push("/order/canceled"), 2000)
      }
    } catch (error: any) {
      Logger.error(error)
      setLoadingCancel(false)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  return {
    handleClickCancelOrder,
    loadingCancel,
    canIuseCancel
  }
}

export default UseCancelOrder