import React, { useState } from "react"
import {
  SideBarLink,
  DropdownLink,
} from "@Components/Theme/Sidebar/Navigation/NavigationMenu.styled"
import { IconSpinner } from "@Theme/common/Icon.styled"
import { IPropsNavigation } from "@Interfaces/IMenu"

const NavigationMenu = ({
  menu: { path, icon, subNav, title, iconOpened, iconClosed },
  toggleMenu,
}: IPropsNavigation) => {
  const [showSubNav, setShowSubNav] = useState(false)

  const handleClickShowSubnav = (e: React.SyntheticEvent) => {
    if (subNav) {
      e.preventDefault()
      setShowSubNav(!showSubNav)
      return
    }

    //Close menu
    toggleMenu()
  }

  const handleClickSubNav = () => toggleMenu()

  return (
    <>
      <SideBarLink to={path} onClick={handleClickShowSubnav} activeClassName="active-option-parent">
        <div className="option-parent">
          {icon}
          <span>{title}</span>
        </div>
        {subNav && showSubNav ? iconOpened : subNav ? iconClosed : null}
      </SideBarLink>
      {showSubNav &&
        subNav &&
        subNav.map((item) => (
          <DropdownLink
            key={item.title}
            to={item.path}
            activeClassName="active-option-child"
            onClick={handleClickSubNav}
          >
            {item.icon}
            <span>{item.subTitle}</span>
            <span className="count-number-intem">
              {item.optionCount === undefined ? <IconSpinner variant="small" /> : item.optionCount}
            </span>
          </DropdownLink>
        ))}
    </>
  )
}

export default NavigationMenu
