import { DateRange, Range } from "react-date-range"
import DateRangeCalendarStyled from "@Components/Commons/Calendar/DateRangeCalendar.styled"
import { es } from "date-fns/locale"
import { IPropsDateRangeCalendar } from "@Interfaces/IDateRangeCalendar"
import { greyScale } from "@Theme/base/Variables"

const DateRangeCalendar = ({ range, setRange }: IPropsDateRangeCalendar) => {
  const handleChangeRange = (range: any) => {
    const { selection } = range as { selection: Range }

    setRange({
      startDate: selection.startDate,
      endDate: selection.endDate,
      key: selection.key,
    })
  }

  return (
    <DateRangeCalendarStyled>
      <DateRange
        onChange={handleChangeRange}
        moveRangeOnFirstSelection={false}
        ranges={[range]}
        rangeColors={[greyScale.grey80]}
        locale={es}
        editableDateInputs={true}
      />
    </DateRangeCalendarStyled>
  )
}

export default DateRangeCalendar
