import { useState, useContext } from "react"
import { GlobalContext } from "@Context/context/GlobalContext"
import { attachFileShippingGroup } from "@Services/ShippingGroupService"
import { updateOrderById } from "@Services/OrderServices"
import Logger from "@Classes/Logger"
import { TransactionCodeEnum } from "@Enums/TransactionCodeEnum"
import { FilesFormType } from "@Types/FilesFormType"
import useUserPermissions from "@Hooks/UseUserPermissions"
import useCancelToken from "@Hooks/UseCancelToken"
import { ResourcePermissionsEnum } from "@Enums/ResourcePermissionsEnum"

const UseDeliveredOrder = ({ shippingGroupId, order }: any) => {
  const [loadingDelivered, setLoadingDelivered] = useState(false)
  const { newCancelToken } = useCancelToken()
  const { notification, errorHander } = useContext(GlobalContext)

  const { permission } = useUserPermissions({
    resources: [ResourcePermissionsEnum.orderDeliveredUploadFiles],
  })

  const attachFile = async (filesShippinGroup: FileList): Promise<boolean> => {
    const formAttachFile = new FormData()

    for (let indexFile = 0; indexFile < filesShippinGroup.length; indexFile++) {
      const file = filesShippinGroup[indexFile]
      if (file) formAttachFile.append(`$attach${indexFile}`, file)
    }

    const dataOrder = await updateOrderById(
      order?.id,
      {
        custom: {
          contrato_portabilidad: "SI",
        },
      },
      newCancelToken(),
    )

    console.log(dataOrder)

    formAttachFile.append("tags", [...filesShippinGroup].map(({ name }) => name).join(","))
    const {
      data: { code },
    } = await attachFileShippingGroup(shippingGroupId, formAttachFile)

    return code === TransactionCodeEnum.ok
  }

  const submitFilesDeliveredOrder = async ({ filesShippinGroup }: FilesFormType) => {
    
    try {
      setLoadingDelivered(true)

      let successAttachFiles: boolean = true

      if (filesShippinGroup) {
        successAttachFiles = await attachFile(filesShippinGroup)
      }

      if (!successAttachFiles) {
        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: "Ha ocurrido un error, al cargar los archivo",
            title: "Error",
            type: "danger",
          },
        })

        return
      }

      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: "Archivos cargados correctamente",
          title: "Hecho",
          type: "success",
        },
      })
      setTimeout(() => window.location.reload(), 1500)
      setLoadingDelivered(false)
    } catch (error: any) {
      setLoadingDelivered(false)
      Logger.error(error)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  return {
    loadingDelivered,
    submitFilesDeliveredOrder,
    hasPermissionUploadFiles: permission[ResourcePermissionsEnum.orderDeliveredUploadFiles],
  }
}

export default UseDeliveredOrder
