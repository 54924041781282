import { ITheme } from "@Interfaces/ITheme"
import { colors, fontSizes, greyScale } from "@Theme/base/Variables"
import { IMultiplesTheme } from "@Interfaces/ITheme"

const defaultTheme: ITheme = {
  palette: {
    primary: "#EA064C",
    secondary: "#E92070",
    blueDark: "#2E62C3",
    ...greyScale,
  },
  colors,
  fontSizes,
  button: {
    success: {
      backgroundColor: colors.green,
      textColor: colors.white,
    },
    danger: {
      backgroundColor: colors.grey100,
      textColor: colors.grey25,
    },
    back: {
      backgroundColor: colors.grey110,
      textColor: colors.white,
    },
    submit: {
      backgroundColor: "#EA064C",
      textColor: greyScale.white,
    },
    primary: {
      textColor: "#FFFFFF",
      backgroundColor: "#1BBE65",
      hoverColor: "#11783F",
    },
    secondary: {
      textColor: "#DBDBDB",
      backgroundColor: "#2E2E2E",
    },
  },
  alert: {
    success: {
      backgroundColor: "#1BBE65",
      textColor: greyScale.white,
    },
    danger: {
      backgroundColor: "#EA064C",
      textColor: greyScale.white,
    },
    info: {
      backgroundColor: greyScale.grey5,
      textColor: greyScale.grey60,
    },
    warning: {
      backgroundColor: "rgb(255, 210, 218)",
      textColor: greyScale.grey60,
    },
    default: {
      backgroundColor: greyScale.white,
      textColor: greyScale.grey60,
    },
  },
  radioButton: {
    color: "#1BBE65",
    hoverColor: "#33CC9E",
  },
  checkbox: {
    color: "#1BBE65",
    hoverColor: "#33CC9E",
  },
}

const womTheme: ITheme = {
  palette: {
    primary: "#451287",
    secondary: "#E92070",
    tertiary: "#4D008C",
    quaternary: "#494365",
    blueDark: "#2E62C3",
    ...greyScale,
  },
  colors,
  fontSizes,
  button: {
    success: {
      backgroundColor: colors.green,
      textColor: colors.white,
    },
    danger: {
      backgroundColor: colors.grey100,
      textColor: colors.grey25,
    },
    back: {
      backgroundColor: colors.grey110,
      textColor: colors.white,
    },
    submit: {
      backgroundColor: "#EA064C",
      textColor: greyScale.white,
    },
    primary: {
      textColor: "#FFFFFF",
      backgroundColor: "#451287",
      hoverColor: "#8C004C",
    },
    secondary: {
      textColor: "#4D008C",
      backgroundColor: "#FFFFFF",
      hoverColor: "#FFFFFF",
      borderColor: "#4D008C",
      focusColor: "#E9DFF9",
      textColorHover: "#E92070",
      borderColorHover: "#E92070",
    },
  },
  alert: {
    success: {
      backgroundColor: "#33CC9E",
      textColor: greyScale.white,
    },
    danger: {
      backgroundColor: "#FD3A4A",
      textColor: greyScale.white,
    },
    info: {
      backgroundColor: greyScale.grey5,
      textColor: greyScale.grey60,
    },
    warning: {
      backgroundColor: "rgb(255, 210, 218)",
      textColor: greyScale.grey60,
    },
    default: {
      backgroundColor: greyScale.white,
      textColor: greyScale.grey60,
    },
  },
  radioButton: {
    color: "#ED1E79",
    hoverColor: "#AA0E51",
  },
  checkbox: {
    color: "#ED1E79",
    hoverColor: "#AA0E51",
  },
}

export const theme: IMultiplesTheme = {
  wom: womTheme,
  default: defaultTheme,
}
