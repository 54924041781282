import { SkeletonTheme } from "react-loading-skeleton"

const SkeletonThemeContainer: React.FC = ({ children }) => {
  return (
    <SkeletonTheme color={"#E0E0E0"} highlightColor={"#F4F5F8"}>
      {children}
    </SkeletonTheme>
  )
}

export default SkeletonThemeContainer
