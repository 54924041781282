import { ComponentsType } from "@Types/CommonsTypes"
import { lazy } from "react"

const ScannerlTransition = lazy(
  () => import("@Components/Core/ProductTransition/ScannerlTransition"),
)
const TagCustomOrder = lazy(() => import("@Webapp-Clients/Wom/Components/TagCustomOrder"))
const FilesList = lazy(() => import("@Components/Core/DocumentsFiles/FilesList"))

const FileUpload = lazy(() => import("@Components/Commons/FileUpload/FileUpalder"))

const ContentFileMedia = lazy(
  () => import("@Webapp-Clients/Wom/Components/ViewContentMedia/ContentFileMedia"),
)

const NoExistTransit = lazy(() => import("@Webapp-Clients/Wom/Components/NoExistTransit"))

export const wom: ComponentsType = {
  ProductTransition: ScannerlTransition,
  TagCustomOrder,
  FilesList,
  FileUpload,
  ContentFileMedia,
  NoExistTransit,
}
