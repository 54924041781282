export enum WebAppClientsEnum {
  wom = "wom",
  privilege = "privilege",
  sandbox = "sandbox",
  shipperto = "shipperto",
}

export enum NoExistDeliveredStatusEnum {
  subStatus = "subStatus",
  statusEnd = "statusEnd",
}

export enum NoExistDeliveredResourcesEnum {
  customerOut = "final-status-courier",
  depleted = "final-status-courier",
  returnOPL = "final-status-courier",
  noDelivered = "final-status-courier",
}
