import { useState, useContext } from "react"
import { GlobalContext } from "@Context/context/GlobalContext"
import { executeShipingGroupAction } from "@Services/ShippingGroupService"
import { TransactionCodeEnum } from "@Enums/TransactionCodeEnum"
import { NOTIFY_SHIPPED } from "@Helpers/constHelper"
import Logger from "@Classes/Logger"
import { ProcessShippingGroupActionType } from "@Types/OrderType"
import { EShippinggroupAction } from "@Enums/shippinggroupEmun"

const useDeliveryOrder = ({
  shippingGroupId,
  setFinishedShippingGroupAction,
}: ProcessShippingGroupActionType) => {
  const [loadingDelivey, setLoadingDelivery] = useState(false)

  const { notification, errorHander } = useContext(GlobalContext)

  const handleClickDeliveryOrder = async () => {
    setLoadingDelivery(true)

    try {
      const {
        data: { code },
      } = await executeShipingGroupAction({
        shippingGroupId,
        action: EShippinggroupAction.shipped,
      })

      setLoadingDelivery(false)

      if (code === TransactionCodeEnum.ok) {
        setFinishedShippingGroupAction(true)

        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: "Productos despachados correctamente",
            title: NOTIFY_SHIPPED,
            type: "success",
          },
        })
      }
    } catch (error: any) {
      setLoadingDelivery(false)
      Logger.error(error)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  return {
    handleClickDeliveryOrder,
    loadingDelivey,
  }
}

export default useDeliveryOrder
