import { respondTo } from "@Theme/base/Mixins"
import { displays } from "@Theme/base/Variables"
import styled from "styled-components"

export const SearchFormStyled = styled.form`
  ${displays.flexCenter};
  padding: 1rem;

  & button {
    border-radius: 10px 0 0 10px;
    padding: 0.5rem 1rem;
    height: unset;

    & svg {
      font-size: 1.2rem;
    }
  }

  & input {
    border-radius: 0 10px 10px 0;
  }
`

export const OptionFilterWrapper = styled.section`
  ${displays.flexWrap}
  padding:  1rem  1rem 0;
  ${respondTo.sm`
      justify-content: center;
  `}
`
