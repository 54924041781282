import styled from "styled-components"
import { displays } from "@Theme/base/Variables"

const OrderContainerStyled = styled.section<{ hasCustomFilters?: boolean }>`
  padding: 0 1rem;

  & .section-filters {
    ${displays.flexWrap};
    justify-content: ${({ hasCustomFilters }) => (hasCustomFilters ? "space-between" : "flex-end")};
    gap: 1rem 0;
  }

  & .content-dispatch-action {
    ${displays.flexBase};
    gap: 15px;
    margin: 1rem 0;
    /* check label */
    & > label {
      width: 100%;
    }

    & > button {
      width: 100%;
    }
  }

  & .content-group {
    & .group-item {
      ${displays.flexCenter};
    }
  }
`
export default OrderContainerStyled
