import { useHistory } from "react-router-dom"
import ProductOderDetail from "@Components/OrderDetail/ProductOrderConfirm/ProductOderDetail"
import PickingAction from "@Components/OrderDetail/ProcessProducts/PickingAction"
import ProcessProductsList from "@Components/OrderDetail/ProcessProducts/ProcessProductsList"
import AlertStyled from "@Theme/common/Alert.styled"
import UseConfirmOrder from "@Pages/DetailOrderScreens/Confirm/UseConfirmOrder"
import { SecondtitleStyled } from "@Theme/common/Paragraph.styled"
import { IProcessItem } from "@Interfaces/IItemsOrder"
import OrderDetailHOC from "@Hoc/OrderDetailHOC"
import { OrderDetailType } from "@Types/OrderType"
import SummaryProductList from "@Components/OrderDetail/SummaryProducts/SummaryProductsList"
import { LinkIconStyled } from "@Theme/common/Link.styled"
import { IconArrowOutlineRigth, IconBackArow, IconCancel } from "@Theme/common/Icon.styled"
import { ButtonStyled } from "@Theme/common/Button.styled"
import ContentDetailOrderActionsStyled from "@Theme/common/ContentDetailOrderActions.styled"
import UseCancelOrder from "@Hooks/UseCancelOrder"
import useCancelDecreaseOrder from "@Hooks/UseCancelDecreaseOrder"
import useHideActionButtons from "@Hooks/UseHideActionButtons"
import { EShippinggroupAction } from "@Enums/shippinggroupEmun"
import { useEffect, useState } from "react"
import Checkbox from "@Components/Commons/CheckBox"
import { useGetCancelActionByClient } from "@Hooks/UseGetMultiClient"

const ConfirmOrderScreen = ({
  order,
  shippinGroupDetail,
  shippingGroupId,
  setFinishedShippingGroupAction,
  finishedShippingGroupAction,
}: OrderDetailType) => {
  const {hideConfirmActions, updateVisibilityButtonActions} = useHideActionButtons()

  useEffect(() => {
    updateVisibilityButtonActions(
      shippinGroupDetail?.source?.id,
      shippinGroupDetail?.target?.source?.id
    )
  }, [shippinGroupDetail, updateVisibilityButtonActions])

  const history = useHistory()

  const itemsOrder = shippinGroupDetail?.source.items

  const [checkedNoLocker, setCheckedNoLocker] = useState(false)

  const handleChageNoLocker = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedNoLocker(!checkedNoLocker)
  }

  const [permissionOrder, actionOrder] = useGetCancelActionByClient()

  const handleClickCancel = () => {
    handleClickCancelOrder(actionOrder)
  }

  const {
    currentItem,
    nextItems,
    itemsAllProcessed,
    loadingConfirm,
    handleClickConfirmOrder,
    backToScan,
    dispachPickingTransition,
    pickingTransition,
    canIuseLocker,
  } = UseConfirmOrder({
    order,
    shippinGroupDetail,
    itemsOrder,
    shippingGroupId,
    checkedNoLocker,
    setFinishedShippingGroupAction,
  })

  const {
    handleClickCancelOrder,
    loadingCancel,
    canIuseCancel
  } = UseCancelOrder({
    order,
    shippingGroupId,
    permissionOrder,
  })

  const {
    handleClickCancelDecreaseOrder,
    loadingCancelDecrase,
    canIuseCancelDecraese
  } = useCancelDecreaseOrder({
    order,
    shippingGroupId
  })
  
  //events
  const handleClickProcessProduct = (item: IProcessItem) => {
    if (item) {
      if (pickingTransition.isEditing) {
        const nextItemIndex = itemsOrder?.findIndex(
          (item) => !pickingTransition.processItems.some(({ sku }) => sku === item.sku),
        )
        dispachPickingTransition({
          type: "EDIT_ITEM_SERIES",
          payload: {
            itemEdit: item,
            nextItemIndex: nextItemIndex || -1,
          },
        })

        return
      }
      dispachPickingTransition({ type: "ADD_PROCESS_ITEM", payload: item })
    }
  }

  const handleClickBackToScan = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    backToScan()
  }
  const handleClickEdit = (sku: string) => {
    dispachPickingTransition({ type: "CHANGE_ITEM_ACTIVE_FOR_EDIT", payload: sku })
  }

  const handleClickCancelDecrease = () => {
    handleClickCancelDecreaseOrder(EShippinggroupAction.cancel_decrease)
  }

  return (
    <>
      {!finishedShippingGroupAction ? (
        <>
          {canIuseCancel && !hideConfirmActions && (
            <ContentDetailOrderActionsStyled>
              <ButtonStyled
                variant="secondary"
                onClick={handleClickCancel}
                loadingButton={loadingCancel}
                disabled={loadingCancel}
              >
                <IconCancel />
                <span>Cancelar Orden</span>
              </ButtonStyled>
            </ContentDetailOrderActionsStyled>
          )}
          
          {canIuseCancelDecraese && !hideConfirmActions && (
            <ContentDetailOrderActionsStyled>
              <ButtonStyled
                variant="secondary"
                onClick={handleClickCancelDecrease}
                loadingButton={loadingCancelDecrase}
                disabled={loadingCancelDecrase}
              >
                <IconCancel />
                <span>Cancelar Orden sin Modificar Inventario</span>
              </ButtonStyled>
            </ContentDetailOrderActionsStyled>
          )}

          {itemsAllProcessed && (
            <LinkIconStyled to="#" className="padding-content" onClick={handleClickBackToScan}>
              <IconBackArow />
              <span>Regresar</span>
            </LinkIconStyled>
          )}
          <AlertStyled variant="default">
            <p>
              Para continuar, necesita escanear <br />
              todos los productos
            </p>
          </AlertStyled>
          {!itemsAllProcessed && (
            <>
              {itemsOrder && (
                <SecondtitleStyled className="m-top1">
                  {pickingTransition.itemIdexActive + 1} de {itemsOrder.length}
                </SecondtitleStyled>
              )}
              <ProductOderDetail
                itemOrder={currentItem}
                onClicProcessProduct={handleClickProcessProduct}
                source={shippinGroupDetail?.source.id}
                hideConfirmActions={hideConfirmActions}
              />
            </>
          )}
          
          {canIuseLocker && (
            <div className="box-checkbox">
              <Checkbox
                id="noLocker"
                name="noLocker"
                onChange={handleChageNoLocker}
                checked={checkedNoLocker}
                label="No asignar locker"
              />
            </div>
          )}

          <ProcessProductsList
            processItems={pickingTransition.processItems}
            onClickEditItem={handleClickEdit}
          />
          
          {nextItems.length > 0 && (
            <>
              <SummaryProductList
                itemsOrder={nextItems}
                groupPicking={false}
                titleMain={"Productos"}
              />
            </>
          )}

          {itemsAllProcessed && (
            <>
              <PickingAction
                loadingConfirm={loadingConfirm}
                onClickConfirmOrder={handleClickConfirmOrder}
              />
            </>
          )}
        </>
      ) : (
        <>
          <div className="padding-content">
            <ButtonStyled
              variant="primary"
              fullSize={true}
              onClick={() =>
                history.push(`/order/package/${shippingGroupId}/${shippinGroupDetail?.orderId}`)
              }
            >
              <span>Ir a empacar</span>
              <IconArrowOutlineRigth />
            </ButtonStyled>
          </div>
          <SummaryProductList itemsOrder={pickingTransition.processItems} groupPicking={true} />
        </>
      )}
    </>
  )
}

export default OrderDetailHOC(ConfirmOrderScreen)
