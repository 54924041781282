import { displays } from "@Theme/base/Variables"
import styled, { css } from "styled-components"

const InputContainerStyled = styled.div`
  position: relative;
  width: 100%;
  font-size: 1.125rem;

  & > label {
    color: ${({ theme }) => theme.palette.grey60};
  }

  & > input {
    border: 1px solid ${({ theme }) => theme.palette.grey60};
    border-radius: 8px;
    background-color: ${({ theme }) => theme.palette.white};
    box-shadow: 0 2px 11px -4px rgba(0, 0, 0, 0.1);
    padding: 0.5rem;
    width: 100%;

    &[type="search"] {
      border: 2px solid #ededed;
      padding: 5px 10px;
      background-color: #f8f8f8;
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);

      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        filter: grayscale(100%);
      }
    }

    &::placeholder {
      color: ${({ theme }) => theme.palette.grey60};
    }

    &:focus {
      border: 2px solid ${({ theme }) => theme.palette.tertiary || theme.palette.grey20};
    }
  }
`

export const ErrorMessageInputStyled = styled.div<{ hasError?: boolean }>`
  width: 100%;
  ${displays.flexColumn};

  ${({ hasError, theme }) =>
    hasError &&
    css`
      &&& input {
        border: 1px solid ${theme.alert.danger.backgroundColor};
      }
    `}

  & .error-message {
    color: ${({ theme }) => theme.palette.white};
    font-size: 1rem;
  }
`

export default InputContainerStyled
