import { useState, useRef } from "react"

import SideBarStyled from "./SideBar.styled"
import { LinkIconStyled } from "@Theme/common/Link.styled"
import Header from "@Components/Theme/Header/Header"
import { IconArrowLeft, IconClose } from "@Theme/common/Icon.styled"
import NavigationMenu from "@Components/Theme/Sidebar/Navigation/NavigationMenu"
import SupportSection from "@Components/Theme/Sidebar/Support/SupportSection"
import useOnClickOutside from "@Hooks/useOnClickOutside"
import useLogin from "@Hooks/UseLogin"
import UseGetMenuUser from "@Hooks/UseGetMenuUser"

const SideBar = () => {
  const [sidebar, setSidebar] = useState(false)
  const refElement = useRef<HTMLDivElement>(null)

  const { getMenuSideBar } = UseGetMenuUser()
  const menu = getMenuSideBar()

  useOnClickOutside({ refElement, handleAction: () => setSidebar(false) })

  const { logout } = useLogin()

  const showSidebar = () => setSidebar(!sidebar)

  const signOut = () => logout()

  return (
    <>
      <Header onShowSidebar={showSidebar} />

      <SideBarStyled sidebar={sidebar} ref={refElement}>
        <div className="sidebar-wraper">
          <div className="header-sidebar">
            <LinkIconStyled to="#" onClick={showSidebar}>
              <IconArrowLeft />
              <span>Cerrar</span>
            </LinkIconStyled>
          </div>
          <section className="option-side-bar">
            {menu?.map((item) => (
              <NavigationMenu menu={item} toggleMenu={showSidebar} key={item.title} />
            ))}
          </section>
          <hr />
          <SupportSection />
          <div className="content-sign-out">
            <LinkIconStyled to="#" onClick={signOut}>
              <IconClose />
              <span>SALIR DE OMNIX</span>
            </LinkIconStyled>
          </div>
        </div>
      </SideBarStyled>
    </>
  )
}

export default SideBar
