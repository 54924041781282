import Skeleton from "react-loading-skeleton"
import { GridContainer } from "@Theme/common/Grid.styled"
import { ISkeleton } from "@Interfaces/ISkeleton"
import SkeletonGroupOrden from "@Components/Commons/Skeleton/SkeletonGroupOrden"

const SkeletonGrid = ({ count, isGroupOrder, className, height = 80 }: ISkeleton) => {
  return (
    <>
      {!isGroupOrder ? (
        <GridContainer className={className}>
          {Array(count)
            .fill(0)
            .map((_, index) => (
              <Skeleton key={index} count={1} height={height}></Skeleton>
            ))}
        </GridContainer>
      ) : (
        <SkeletonGroupOrden count={count} />
      )}
    </>
  )
}
export default SkeletonGrid
