import SummaryProductList from "@Components/OrderDetail/SummaryProducts/SummaryProductsList"
import OrderDetailHOC from "@Hoc/OrderDetailHOC"
import { OrderDetailType } from "@Types/OrderType"
import useTransitOrder from "@Pages/DetailOrderScreens/Transit/UseTransitOrder"
import TransitAction from "@Components/OrderDetail/TransitProductOrder/TransitAction"
import SkeletonShippinGroupDetail from "@Components/Commons/Skeleton/SkeletonShippinGroupDetail"
import TagPrintContent from "@Components/OrderDetail/TagPrint/TagPrintContent"
import { Suspense, useContext, useEffect } from "react"
import { AuthContext } from "@Context/context/AuthContext"
import { clients } from "@Webapp-Clients/Clients"
import AlertStyled from "@Theme/common/Alert.styled"
import { IconAttachment } from "@Theme/common/Icon.styled"
import { ErrorFieldForm } from "@Theme/common/Paragraph.styled"
import useHideActionButtons from "@Hooks/UseHideActionButtons"
import { IconCancel } from "@Theme/common/Icon.styled"
import { ButtonStyled } from "@Theme/common/Button.styled"
import ContentDetailOrderActionsStyled from "@Theme/common/ContentDetailOrderActions.styled"
import { EShippinggroupAction } from "@Enums/shippinggroupEmun"
import useCancelDecreaseOrder from "@Hooks/UseCancelDecreaseOrder"
import UseDeliveredRejectOrder from "@Hooks/UseDeliveredRejectOrder"
import { WebAppClientsEnum } from "@Enums/CommonEnum"
import UseCancelOrder from "@Hooks/UseCancelOrder"
import { useGetCancelActionByClient } from "@Hooks/UseGetMultiClient"


// TODO: multiclient
import useFormConfig from "@Hooks/Media/useFormConfig"
import useFormConfigSandbox from "@themesandbox/base/Hooks/Media/useFormConfig"

const TransitScreen = ({
  shippinGroupDetail,
  shippingGroupId,
  setFinishedShippingGroupAction,
  finishedShippingGroupAction,
  order,
}: OrderDetailType) => {
  const {
    state: { user },
  } = useContext(AuthContext)
  const { hideTransitActions, updateVisibilityButtonActions } = useHideActionButtons()

  useEffect(() => {
    updateVisibilityButtonActions(
      shippinGroupDetail?.source?.id,
      shippinGroupDetail?.target?.source?.id,
    )
  }, [shippinGroupDetail, updateVisibilityButtonActions])

  const client = clients[user?.mainGroup.id as keyof typeof clients]
  const FilesList = client["FilesList" as keyof typeof client]
  const itemsOrder = shippinGroupDetail?.source.items
  const documents = shippinGroupDetail?.custom?.documents
  const elockerInfo = shippinGroupDetail?.custom?.infoElocker
  const NoExistTransit = client["NoExistTransit" as keyof typeof client]

  // TODO: multiclient
  const useFormConfigWrapper = (user?.mainGroup.id === "sandbox") ? useFormConfigSandbox : useFormConfig
  const { errors, FileUpload, handleSubmit, register, setValue } = useFormConfigWrapper()

  const { submitDeliveredOrder, loadingTransit } = useTransitOrder({
    shippingGroupId,
    setFinishedShippingGroupAction,
    order,
  })

  const { handleClickCancelDecreaseOrder, loadingCancelDecrase, canIuseCancelDecraese } =
    useCancelDecreaseOrder({
      order,
      shippingGroupId,
    })

  const { handleClickDeliveredRejectOrder, loadingDeliveredReject, canIuseDeliveredReject } =
    UseDeliveredRejectOrder({
      order,
      shippingGroupId,
    })

  const handleClickCancelDecrease = () => {
    handleClickCancelDecreaseOrder(EShippinggroupAction.cancel_decrease)
  }

  const handleClickDeliveredReject = () => {
    handleClickDeliveredRejectOrder(EShippinggroupAction.delivered_reject)
  }

  const [permissionOrder, actionOrder] = useGetCancelActionByClient()

  const handleClickCancel = () => {
    handleClickCancelOrder(actionOrder)
  }

  const { handleClickCancelOrder, loadingCancel, canIuseCancel } = UseCancelOrder({
    order,
    shippingGroupId,
    permissionOrder,
  })

  return (
    <>
      {NoExistTransit && (
        <Suspense fallback={null}>
          <NoExistTransit
            shippingGroupId={shippingGroupId}
            courierDeliveryInfo={shippinGroupDetail?.custom?.courierDeliveryInfo}
            shippinGroupDetail={shippinGroupDetail}
          />
        </Suspense>
      )}
      {itemsOrder ? (
        <>
          {canIuseCancel && window?.$website === WebAppClientsEnum.sandbox && (
            <ContentDetailOrderActionsStyled>
              <ButtonStyled
                variant="secondary"
                onClick={handleClickCancel}
                loadingButton={loadingCancel}
                disabled={loadingCancel}
              >
                <IconCancel />
                <span>Cancelar Orden</span>
              </ButtonStyled>
            </ContentDetailOrderActionsStyled>
          )}

          <SummaryProductList
            itemsOrder={itemsOrder}
            groupPicking={false}
            titleMain={finishedShippingGroupAction ? "Productos en tránsito" : undefined}
          />
          {!finishedShippingGroupAction && (
            <>
              <form onSubmit={handleSubmit(submitDeliveredOrder)}>
                {FileUpload && (
                  <Suspense fallback={null}>
                    <section className="padding-content ">
                      <AlertStyled variant="info" className="m-top-button1">
                        <IconAttachment />
                        <p>Adjuntar máximo 3 archivos</p>
                      </AlertStyled>
                      <FileUpload register={register} setValue={setValue} />
                    </section>
                  </Suspense>
                )}

                {errors.filesShippinGroup && (
                  <ErrorFieldForm>{errors.filesShippinGroup.message}</ErrorFieldForm>
                )}

                {canIuseCancelDecraese && (
                  <ContentDetailOrderActionsStyled>
                    <ButtonStyled
                      variant="secondary"
                      onClick={handleClickCancelDecrease}
                      loadingButton={loadingCancelDecrase}
                      disabled={loadingCancelDecrase}
                    >
                      <IconCancel />
                      <span>Cancelar Orden sin Modificar Inventario</span>
                    </ButtonStyled>
                  </ContentDetailOrderActionsStyled>
                )}

                {canIuseDeliveredReject && (
                  <ContentDetailOrderActionsStyled>
                    <ButtonStyled
                      variant="secondary"
                      onClick={handleClickDeliveredReject}
                      loadingButton={loadingDeliveredReject}
                      disabled={loadingDeliveredReject}
                    >
                      <IconCancel />
                      <span>Cancelar orden por siniestro</span>
                    </ButtonStyled>
                  </ContentDetailOrderActionsStyled>
                )}

                {!hideTransitActions && <TransitAction loadingTransit={loadingTransit} />}
              </form>
            </>
          )}
          {order && (
            <TagPrintContent
              codigoEntrega={(elockerInfo?.isElocker && elockerInfo?.codigoEntrega) || null}
              order={order}
              shippingGroupId={shippingGroupId}
            />
          )}
          {FilesList && (
            <Suspense fallback={null}>
              <FilesList
                documents={documents}
                channel={shippinGroupDetail?.channel}
                orderId={order?.id}
              />
            </Suspense>
          )}
        </>
      ) : (
        <SkeletonShippinGroupDetail />
      )}
    </>
  )
}

export default OrderDetailHOC(TransitScreen)
