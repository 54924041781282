import { TypeVariation5 } from "@Types/CommonsTypes"
import { WEB_SITES } from "./constHelper"

/*

  Return current website

  @param {String | null} manualEnv  in this value is null the fuction will check the url to find the current client 
  if is not null this will set the website with his value
  @return {String} current website

  */

export const getWebsite = (manualEnv: TypeVariation5 = null) => {
  const url: string = window.location.href

  if (manualEnv) {
    if (WEB_SITES.includes(manualEnv)) {

      return manualEnv
    } else {
      throw new Error("Website don't exist")
    }
  }

  if (url.includes("localhost")) {
    return "default"
  } else {
    const whichOne = WEB_SITES.find((site) => url.includes(site))
    return whichOne || "default"
  }
}

export const pathLogClient = ["wom"]
