import { ButtonStyled } from "@Theme/common/Button.styled"
import ContentDetailOrderActionsStyled from "@Theme/common/ContentDetailOrderActions.styled"
import { IconCheck } from "@Theme/common/Icon.styled"
import { IPackingAction } from "@Interfaces/IShippingGroupAction"

const PackageAction = ({ loadingPaking, onClickPackageOrder }: IPackingAction) => {
  return (
    <ContentDetailOrderActionsStyled>
      <ButtonStyled
        variant="primary"
        loadingButton={loadingPaking}
        disabled={loadingPaking}
        onClick={onClickPackageOrder}
      >
        <IconCheck />
        <span>Confirmar Empacado</span>
      </ButtonStyled>
    </ContentDetailOrderActionsStyled>
  )
}

export default PackageAction
