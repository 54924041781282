import { ButtonStyled } from "@Theme/common/Button.styled"
import ContentDetailOrderActionsStyled from "@Theme/common/ContentDetailOrderActions.styled"
import { IconCheck } from "@Theme/common/Icon.styled"
import { ITransitAction } from "@Interfaces/IShippingGroupAction"

const TransitAction = ({ loadingTransit }: ITransitAction) => {
  // TODO: This could be refactorized

  return (
    <ContentDetailOrderActionsStyled>
      <ButtonStyled variant="primary" loadingButton={loadingTransit} disabled={loadingTransit}>
        <IconCheck />
        {/* // TODO: This could be refactorized */}
        <span>Confirmar retiro preparado</span>
      </ButtonStyled>
    </ContentDetailOrderActionsStyled>
  )
}

export default TransitAction
