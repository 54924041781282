export default class Env {
  static _comment = "Information about the environment. @version 1.0.1 @author Naldo Duran<naldorck@gmail.com>"

  static clients = ["localhost", "wom", "privilege", "sandbox", "shiperto", "sodimac"]

  static env = ["dev", "uat", "prod"]

  static sg_shipping_types = ["HD", "SP", "RT", "TR"]

  static sg_actions = [
    "accept",
    "packed",
    "shipped",
    "delivered",
    "prepared",
    "delivered_reject",
    "picked_up",
    "reject",
    "cancel",
    "cancel_decrease",
    "cancelLogistic ",
  ]

  static sg_status = [
    "AWAITING_STORE_CONFIRMATION",
    "PENDING_PACKAGE",
    "AWAITING_DELIVERY",
    "IN_TRANSIT",
    "READY_FOR_PICKUP",
    "IN_SOURCE_PREPARATION",
    "RETURN_IN_REVIEW",
    "DELIVERED",
    "CANCELED",
    "CANCELED_DECREASE",
    "CLOSED",    
  ]

  static sg_shippingtype_rt_status = [
    this.sg_status[6], //RETURN_IN_REVIEW
  ]

  // GET this from service
  static sg_status_action = {
    [this.sg_status[0]]: this.sg_actions[0],
    [this.sg_status[5]]: this.sg_actions[4], // IN_SOURCE_PREPARATION ->prepared
  }

  static theme = {
    core: {
      id: "core",
      sg: {
        sg_shipping_types: this.sg_shipping_types,
        default: {          
          status: this.sg_status,
          actions: this.sg_actions,
          statusActions: this.sg_status_action,
        },
      },
    },
    wom: {
      id: this.clients[1],
    },
    privilege: {
      id: this.clients[2],
    },
    sandbox: {
      id: this.clients[3],
    },
    shipperto: {
      id: this.clients[4],
    },
    sodimac: {
      id: this.clients[5],
    },
  }
}
