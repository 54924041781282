import { ButtonStyled } from "@Theme/common/Button.styled"
import { IconArrowLeft } from "@Theme/common/Icon.styled"
import { useHistory } from "react-router-dom"
import HomeButtonStyled from "./HomeButton.styled"

const HomeBotton = ({ onClickBackBotton }: { onClickBackBotton?: () => void }) => {
  const history = useHistory()

  const handleClickBackHome = () => {
    history.push("/")
    onClickBackBotton && onClickBackBotton()
  }

  return (
    <HomeButtonStyled>
      <ButtonStyled variant="secondary" onClick={handleClickBackHome}>
        <IconArrowLeft />
        <span>Volver al inicio</span>
      </ButtonStyled>
    </HomeButtonStyled>
  )
}

export default HomeBotton
