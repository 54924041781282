import { useMemo, useContext } from "react"
import CardOptionStyled from "@Components/Summary/SummaryOption/CardOption.styled"
import { abbreviateNumber } from "@Helpers/UtilsHelper"
import { IMenu } from "@Interfaces/IMenu"
import { IconArrowRigth, IconSpinner } from "@Theme/common/Icon.styled"
import { AuthContext } from "@Context/context/AuthContext"
import { ResourceRoleEnum } from "@Enums/ResourceRoleEnum"

const CardOption = ({ index, icon, optionCount, title, path, stateLocation }: IMenu) => {
  const countAbbreviate = useMemo(() => abbreviateNumber(optionCount || 0), [optionCount])
  
  const {
    state: { user },
  } = useContext(AuthContext)

  const monitorUser = () => {
    return user?.role === ResourceRoleEnum["webapp-monitor-source"]
  }

  const specialClass = () => {
    return ((countAbbreviate > 0) && (index === 0) && monitorUser())
  }

  return (
    <CardOptionStyled
      className={specialClass() ? "topseller" : ""}
      to={{
        pathname: path,
        state: stateLocation,
      }}
    >
      <div className="section-count">
        <div className="icon-option">{icon}</div>
        <p className="option-count">
          {optionCount === undefined ? <IconSpinner variant="big" /> : countAbbreviate}
        </p>
      </div>
      <div className="section-title">
        <p className="option-name">{title}</p>
        <IconArrowRigth className="icon-arrow" />
      </div>
      {/* <mark>99</mark> */}
    </CardOptionStyled>
  )
}

export default CardOption
