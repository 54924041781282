import { ButtonStyled } from "@Theme/common/Button.styled"
import ContentDetailOrderActionsStyled from "@Theme/common/ContentDetailOrderActions.styled"
import { IconCheck, IconCancel } from "@Theme/common/Icon.styled"
import { IPickupAction } from "@Interfaces/IShippingGroupAction"

const PickupAction = ({
  loadingPickup,
  loadingCancel,
  onClickPickup,
  onClickCancel,
  shouldBeFilePresentOnSubmit,
}: IPickupAction) => {
  return (
    <ContentDetailOrderActionsStyled>
      {onClickPickup && !shouldBeFilePresentOnSubmit() && (
        <ButtonStyled
          variant="primary"
          loadingButton={loadingPickup}
          disabled={loadingPickup}
          onClick={onClickPickup}
        >
          <IconCheck />
          <span>Confirmar entrega</span>
        </ButtonStyled>
      )}

      {onClickCancel && (
        <ButtonStyled
          variant="secondary"
          loadingButton={loadingCancel}
          disabled={loadingCancel}
          onClick={onClickCancel}
        >
          <IconCancel />
          <span>Cancelar orden</span>
        </ButtonStyled>
      )}
    </ContentDetailOrderActionsStyled>
  )
}

export default PickupAction
