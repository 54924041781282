import { displays } from "@Theme/base/Variables"
import styled from "styled-components"

const ContentDetailOrderActionsStyled = styled.section`
  ${displays.flexColumn}
  padding: 1rem;
  gap: 1rem;

  & > button {
    width: 100%;
  }
`

export default ContentDetailOrderActionsStyled
