import { FunctionComponent, useEffect, useContext } from "react"
import Logger from "@Classes/Logger"
import IPage from "@Interfaces/IPage"
import { GlobalContext } from "@Context/context/GlobalContext"
import UserProfileForm from "@Components/User/Profile/UserProfileForm"

const ProfileScreen: FunctionComponent<IPage> = (props: any) => {
  const { name } = props

  const { userProfileHeader } = useContext(GlobalContext)

  useEffect(() => {
    Logger.info(`Loading ${name}`)

    //Show orofile user in header
    userProfileHeader?.dispatch(true)

    return () => userProfileHeader?.dispatch(false)
  }, [name, userProfileHeader])

  return <UserProfileForm />
}

export default ProfileScreen
