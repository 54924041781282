import { useContext } from "react"
import { BrowserRouter, Switch } from "react-router-dom"
import PrivateRouteComponent from "@Components/Commons/PrivateRouteComponent"
import PublicRouteComponent from "@Components/Commons/PublicRouteComponent"
import { AuthContext } from "@Context/context/AuthContext"
import DashboarContainer from "@Containers/DashBoard/DashboardContainer"
import LoginScreen from "@Pages/UserScreens/LoginScreen/LoginScreen"

const AppRouter = () => {
  const {
    state: { user, optionsMenuAccess },
  } = useContext(AuthContext)

  const isLogged = (user && optionsMenuAccess.length > 0) || false
  return (
    <BrowserRouter>
      <Switch>
        <PublicRouteComponent
          exact
          path="/login"
          isAuthenticated={isLogged}
          redirectPath="/dashboard"
          render={(props) => <LoginScreen {...props} name="LoginScreen" />}
        />
        <PrivateRouteComponent
          path="/"
          component={DashboarContainer}
          isAuthenticated={isLogged}
          redirectPath="/login"
        />
      </Switch>
    </BrowserRouter>
  )
}

export default AppRouter
