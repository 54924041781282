import styled, { css } from "styled-components"
import { displays } from "@Theme/base/Variables"
import { respondTo } from "@Theme/base/Mixins"

const OrderFilterDateStyled = styled.div`
  ${displays.flexBase};
  gap: 0 1rem;

  & .content-date {
    ${displays.flexBase};
    gap: 10px;
    & > svg.calendar {
      color: ${({ theme }) => theme.palette.grey60};
      font-size: 1.5rem;
    }

    & .info-date {
      ${displays.flexBase};
      color: ${({ theme }) => theme.palette.grey90};
      border: 1px solid ${({ theme }) => theme.palette.quaternary || theme.palette.grey20};
      border-radius: 18px;
      background: ${({ theme }) =>
        css`linear-gradient(0deg, ${theme.palette.grey5} 0%, ${theme.palette.white} 100%)`};
      padding: 0.2rem 0.5rem;
      cursor: pointer;
      min-width: 200px;
      height: 36px;
      position: relative;

      & svg {
        position: absolute;
        right: 5px;
        font-size: 1.5rem;
        color: ${({ theme }) => theme.palette.primary};
        margin-left: 10px;
      }

      ${respondTo.sm`
           min-width: 220px;
      `}
    }
  }
`

export default OrderFilterDateStyled
