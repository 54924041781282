import { ICheckbox } from "@Interfaces/ICheckbox"
import { CheckboxContainer, HiddenCheckbox, StyledCheckbox } from "@Theme/common/CheckBox"
import { IconBoxCheck } from "@Theme/common/Icon.styled"

const Checkbox = ({ className, checked, label, ...props }: ICheckbox) => (
  <label>
    <CheckboxContainer className={className}>
      <HiddenCheckbox {...props} />
      <StyledCheckbox checked={checked}>
        <IconBoxCheck />
      </StyledCheckbox>
    </CheckboxContainer>
    {label && <span>{label}</span>}
  </label>
)

export default Checkbox
