import { displays } from "@Theme/base/Variables"
import styled from "styled-components"

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
`

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const StyledCheckbox = styled.div<{ checked?: boolean }>`
  ${displays.flexCenter};
  width: 18px;
  height: 18px;
  background: ${({ theme, checked }) => (checked ? theme.checkbox.color : theme.palette.white)};
  border: 1px solid ${({ theme }) => theme.checkbox.color};
  border-radius: 2px;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.palette.grey5};
  }

  & svg {
    color: ${({ theme }) => theme.palette.white};
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
    stroke-width: 2px;
  }
`
