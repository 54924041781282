import React, { useState } from "react"
import { addDays } from "date-fns"
import ContentCalendarStyled from "@Components/Core/Order/FilterDate/ContentCalendar/ContentCalendar.styled"
import ButtonOptionCalendar from "@Components/Core/Order/FilterDate/ContentCalendar/ButtonOptionCalendar"
import DateRangeCalendar from "@Components/Commons/Calendar/DateRangeCalendar"
import { ButtonStyled } from "@Theme/common/Button.styled"
import { IconCheck } from "@Theme/common/Icon.styled"
import { IContentCalendar, IRangeExtend } from "@Interfaces/IContentCelendar"
import { FilterOptionsCalendarEnum } from "@Enums/FilterOptionsCalendarEnum"
import { rangeDefault } from "@Helpers/InitStateGeneral"

const ContentCalendar = ({ closeModal, onChangeRangeDate, initRange }: IContentCalendar) => {
  const [range, setRange] = useState<IRangeExtend>(initRange || rangeDefault)

  //events
  const handleClickToday = (e: React.MouseEvent<HTMLButtonElement>) => {
    setRange({
      ...range,
      startDate: new Date(),
      endDate: new Date(),
      activeOptionFilter: parseInt(e.currentTarget.id, 0),
    })
  }

  const handleClickYesterday = (e: React.MouseEvent<HTMLButtonElement>) => {
    setRange({
      ...range,
      startDate: addDays(new Date(), -1),
      endDate: addDays(new Date(), -1),
      activeOptionFilter: parseInt(e.currentTarget.id, 0),
    })
  }

  const handleClickLastWeek = (e: React.MouseEvent<HTMLButtonElement>) => {
    setRange({
      ...range,
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      activeOptionFilter: parseInt(e.currentTarget.id, 0),
    })
  }

  const handleClickFilter = () => {
    closeModal && closeModal()

    onChangeRangeDate(range)
  }

  return (
    <ContentCalendarStyled>
      <div className="content-option-calendar ">
        <ButtonOptionCalendar
          text="Hoy"
          active={range.activeOptionFilter === FilterOptionsCalendarEnum.today}
          onclick={handleClickToday}
          id={FilterOptionsCalendarEnum.today}
        />
        <ButtonOptionCalendar
          text="Ayer"
          active={range.activeOptionFilter === FilterOptionsCalendarEnum.yesteday}
          onclick={handleClickYesterday}
          id={FilterOptionsCalendarEnum.yesteday}
        />
        <ButtonOptionCalendar
          text="Últimos 7 días"
          active={range.activeOptionFilter === FilterOptionsCalendarEnum.lastWeek}
          onclick={handleClickLastWeek}
          id={FilterOptionsCalendarEnum.lastWeek}
        />
      </div>
      <div className="content-calendar-body">
        <DateRangeCalendar range={range} setRange={setRange} />
        <ButtonStyled variant="primary" onClick={handleClickFilter}>
          <IconCheck />
          <span>Filtrar</span>
        </ButtonStyled>
      </div>
    </ContentCalendarStyled>
  )
}

export default ContentCalendar
