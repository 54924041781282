import { memo, useContext } from "react"
import { AuthContext } from "@Context/context/AuthContext"
import { pathLogClient } from "@Helpers/websiteHelper"

const LogoClient = memo(({ render }: { render?: (logo: JSX.Element) => JSX.Element }) => {
  const authState = useContext(AuthContext)
  const user = authState?.state.user

  //get url logo by client pathLogClient config
  const hasLogoClient = user && pathLogClient.includes(user.mainGroup.id)

  if (!hasLogoClient) return null

  return (
    <>
      {render ? (
        render(
          <img
            src={require(`@Theme/assets/img/LogosClients/${user?.mainGroup.id}.svg`).default}
            alt="logo"
          />,
        )
      ) : (
        <img
          src={require(`@Theme/assets/img/LogosClients/${user?.mainGroup.id}.svg`).default}
          alt="logo"
        />
      )}
    </>
  )
})

export default LogoClient
